import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./contactInformation.styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

class ContactInformation extends Component {
  state = {};

  render() {
    const { classes, dataholder } = this.props;

    return (
      <div className={classes.root}>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Datos de contacto</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <form>

            


            <Grid container spacing={24}>
              {
                dataholder.listaContactos.map((contact, index) => {
                  return(
                  <Grid key={'container-' + index} container spacing={24}>
                  <Grid key={'row-person-' + index} item xs>
                      <TextField
                        key={'dataholder-person' + index}
                        id="dataholder-person"
                        label="Persona de contacto"
                        margin="normal"
                        value={contact.personaContacto ? contact.personaContacto: ''}
                        disabled
                      />
                    </Grid>
                    <Grid key={'row-email-' + index} item xs>
                      <TextField
                        key={'dataholder-email' + index}
                        id="dataholder-email"
                        label="Email"
                        margin="normal"
                        value={contact.email}
                        disabled
                      />
                    </Grid>
                    <Grid key={'row-phone-' + index} item xs>
                      <TextField
                        key={'dataholder-phone' + index}
                        id="dataholder-phone"
                        label="Teléfono"
                        margin="normal"
                        value={contact.telefono}
                        disabled
                      />
                    </Grid>
                  </Grid>
                )})
              }
              </Grid>
            </form>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(ContactInformation);
