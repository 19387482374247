import React, { Component, Suspense } from 'react';
import 'babel-polyfill';
import LayoutContainer from './components/layout/layoutContainer';
import { MuiThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { theme as defaultTheme } from './themes/default/theme';
import * as oDataAuthService from './services/oDataAuth.service';
import * as appConfigurationService from "./services/appConfiguration.service";
import { connect } from 'react-redux';
import { fetchInitData } from './redux/actions/core.actions';
import { initializeHttpInterceptors } from './services/interceptors';
import { Loading } from './components';
import { getHostPrefix } from './helpers';
import { withStyles } from '@material-ui/core/styles';
import { useEffect } from "react";
// MSAL imports 
import { InteractionStatus, InteractionType, EventType } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated, MsalContext, AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
// MSAL imports 


const CustomLinearProgress = withStyles({
    root: {
        margin: '-8px'
    },
    colorPrimary: {
        backgroundColor: '#e6375a'
    },
    barColorPrimary: {
        backgroundColor: '#fff'
    }
})(LinearProgress);

const mapStateToProps = state => {
    return { user: state.userName };
};

function mapDispatchToProps(dispatch) {
    return {
        fetchInitData: () => dispatch(fetchInitData())
    };
}
 
class App extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
            customTheme: defaultTheme,
            userName: ''
        };

        // load theme for the client's host
        const urlHostPrefix = getHostPrefix();
        import(/* webpackMode: "eager" */ `./themes/${urlHostPrefix}/theme.js`)
            .then(loadedModule => {
                this.setState({ customTheme: loadedModule.theme });
            })
            .catch(error => {
                // No theme was found for the current url Host. Default theme will be used.
                console.log(error);

            });
    }
   
    componentDidMount() {
       
        // This will be run on component mount        
        const callbackId = this.context.instance.addEventCallback(async (message) => {
 
            // This will be run every time an event is emitted after registering this callback            
            if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {

                const oDataToken = await oDataAuthService.getToken(message.payload.idToken); 
                localStorage.setItem('oDataAuthToken', oDataToken && oDataToken.access_token);

                //await this.props.fetchAppConfiguration(message.payload.idToken);

                const config = await appConfigurationService.fetchConfiguration(message.payload.idToken); 
                localStorage.setItem('configuration', JSON.stringify(config));

                initializeHttpInterceptors(); 
                await this.props.fetchInitData();
            }
            if (message.eventType === EventType.LOGIN_SUCCESS) {
               
                this.setState({ userName: message.payload.account.name });
            }
        });
         
        this.setState({ userName: callbackId });
        
    }

    componentWillUnmount() {
        // This will be run on component unmount        
        if (this.state.callbackId) { 
            this.context.instance.removeEventCallback(this.state.callbackId);            
        }
         
    }

 
    static contextType = MsalContext;
    render() { 
        return ( 
            <>
                <AuthenticatedTemplate>          
                </AuthenticatedTemplate>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
                    <Suspense fallback={<Loading modal />}>
                        <MuiThemeProvider theme={this.state.customTheme}>
                            <ProtectedData  />
                        </MuiThemeProvider>
                    </Suspense>
                </MsalAuthenticationTemplate>
                <UnauthenticatedTemplate>
                    <CustomLinearProgress />
                </UnauthenticatedTemplate>
            </> 
        ); 
    }
}

export function ProtectedData( ) {
    const { accounts, instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
   
    useEffect(() => {

       
        async function getToken(accounts, instance) {           
            if (accounts.length > 0) {
                const request = {
                    scopes: loginRequest.scopes,
                    account: accounts[0]
                }
                const accessToken = await instance.acquireTokenSilent(request)
                    .then((response) => { 
                        return response.idToken;
                    }).catch(error => {
                        // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
                        console.log(error);
                        return null;
                    });

                return accessToken;
            }
            return null;
        }

        if (isAuthenticated && inProgress === InteractionStatus.None) {
           
            if (accounts.length > 0) {             
                getToken(accounts, instance);
            } 
        }
    }, [isAuthenticated, inProgress, instance]); 

    return (<LayoutContainer userName={accounts[0] && accounts[0].name} />);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
 