import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import styles from "./dataholderInformation.styles";

class DataholderLegalTypeInformation extends Component {
  state = {};

  render() {
    const { dataholder } = this.props;

    return (
      <form>
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <TextField
              id="dataholder-company"
              label="Empresa"
              margin="normal"
              value={dataholder.razonSocial}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="owner-identification-number"
              label="NIF"
              margin="normal"
              value={dataholder.documentoFiscal}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs>
            <TextField
              id="dataholder-address"
              label="Dirección"
              margin="normal"
              value={dataholder.direccion.direccion}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-postal-code"
              label="Código postal"
              margin="normal"
              value={dataholder.direccion.direccionCP}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-location"
              label="Municipio"
              margin="normal"
              value={dataholder.direccion.direccionMunicipio}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-province"
              label="Provincia"
              margin="normal"
              value={dataholder.direccion.direccionProvincia}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-country"
              label="Pais"
              margin="normal"
              value={dataholder.direccion.direccionPais}
              disabled
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(DataholderLegalTypeInformation);
