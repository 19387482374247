export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing.unit
  },
  title: {
    flex: 1
  },
  chartFilters: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end"
  },
  chartPaper: {
    minHeight: "300px",
    padding: theme.spacing.unit * 3
  },
  loadingContainer: {
    marginTop: "40px"
  }
});
