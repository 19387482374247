import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import InvoiceList from "./invoiceList/invoiceList";
import Typography from "@material-ui/core/Typography";
import {
  Hidden, FormControl, TextField
} from "@material-ui/core";
import { connect } from "react-redux";
import { selectContract } from "../../redux/actions/contracts.actions";

const mapStateToProps = state => {
  return {
    appTitle: state.core.appTitle,
    invoices: state.invoices.invoices,
    contracts: state.contracts.contracts,
    contract: state.contracts.contract
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectContract: contractId => dispatch(selectContract(contractId))
  };
}

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px"
  }
});

class InvoicesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractInvoices: null,
      availableInvoices: null,
      selectedContract: null
    };
    this.handleContractChange = this.handleContractChange.bind(this);
  }

  handleContractChange(e) {
    this.props.selectContract(e.target.value);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { invoices, contract } = nextProps;
    const contractInvoices = contract && invoices && invoices.filter(
      invoice => invoice.contrato.id === contract.id
    );
    return {
      availableInvoices: invoices,
      selectedContract: contract,
      contractInvoices: contractInvoices
    };
  }

  render() {
    const { classes, appTitle, contracts, t } = this.props;
    const { selectedContract, contractInvoices } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <Hidden smDown implementation="css">
            <Typography variant="h5" gutterBottom component="h2">
              {appTitle}
            </Typography>
          </Hidden>
          {contracts && contracts.length > 1 && (
            <FormControl className={classes.formControl}>
              <TextField
                select
                variant="outlined"
                margin="dense"
                id="contract"
                inputProps={{
                  name: "contract",
                  id: "contract"
                }}
                inputlabelprops={{
                  shrink: "true"
                }}
                label={t('invoices.contractInputLabel')}
                value={selectedContract && selectedContract.id}
                onChange={this.handleContractChange}
                SelectProps={{
                  native: true
                }}
              >
                {contracts.map((dt, i) => {
                  return (
                    <option key={i} value={dt.id}>
                      {dt.codContrato} - {dt.descripcion}
                    </option>
                  );
                })}
              </TextField>
            </FormControl>
          )}
        </div>
        <InvoiceList
          invoices={contractInvoices}
          triggerChangePage={this.changePage}
        />
      </div>
    );
  }
}

const Invoices = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesContainer);
export default withTranslation()(withStyles(styles)(Invoices));
