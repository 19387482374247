import { FETCH_INVOICES_SUCCESS} from "../constants/action-types";

const initialState = {
  invoices: [],
  lastInvoice: null
};

function invoicesReducer(state = initialState, action) {
  if (action.type === FETCH_INVOICES_SUCCESS) {
    return Object.assign({}, state, {
      invoices: action.payload,
      lastInvoice: action.payload && action.payload.length ? action.payload[0] : null
    });
  }
  return state;
}
export default invoicesReducer;