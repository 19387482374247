export default theme => ({
  root: {
    width: '100%'
  },
  invoice: {
    display: 'flex',
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.background.paper
  },
  invoiceDataContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  invoiceData: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: theme.spacing.unit
  },
  invoiceActions: {
    alignSelf: 'flex-start'
  },
  loadingContainer: {
    marginTop: '40px'
  }
});
