import { combineReducers } from 'redux';
import coreReducer from './core.reducer';
import contractsReducer from './contracts.reducer';
import dataholderReducer from './dataholder.reducer';
import invoicesReducer from './invoices.reducer';
import userReducer from './user.reducer';
import cupsReducer from './cups.reducer';

export default combineReducers({
  core: coreReducer,
  contracts: contractsReducer,
  dataholder: dataholderReducer,
  invoices: invoicesReducer,
  user: userReducer,
  cups: cupsReducer
})

export const initialState = {
  core: coreReducer.initialState,
  contracts: contractsReducer.initialState,
  dataholder: dataholderReducer.initialState,
  invoices: invoicesReducer.initialState,
  user: userReducer.initialState,
  cups: cupsReducer.invitialState
}