import React, { Component } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./paymentMethods.styles";
import {
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

class PaymentMethods extends Component {
  state = {};

  render() {
    const { classes, contracts } = this.props;

    if (!contracts) {
      return null;
    }

    return (
      <div className={classes.root}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>Métodos de pago</Typography>
            <Typography className={classes.secondaryHeading}>
              Gestione sus diferentes métodos de pago activos
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Contrato</TableCell>
                  <TableCell>Periodo contrato</TableCell>
                  <TableCell>Método de pago</TableCell>
                  <TableCell>Entidad bancaria</TableCell>
                  <TableCell>IBAN</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts.map((contract, index) => {
                  return (
                    <TableRow key={contract.id}>
                      <TableCell>{contract.codContrato}</TableCell>
                      <TableCell>
                        {new Date(contract.fechaInicio).toLocaleDateString() +
                          " - " +
                          new Date(contract.fechaFin).toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {contract.metodoPago.tipoMetodoPago}
                      </TableCell>
                      <TableCell>
                        {contract.metodoPago.entidad &&
                          contract.metodoPago.entidad}
                      </TableCell>
                      <TableCell>{contract.metodoPago.iban}</TableCell>
                      <TableCell>
                        <EditIcon />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(PaymentMethods);
