import React, { Component } from "react";
import { Line, Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";
import { withTheme } from "@material-ui/core";

const numberFormat = new Intl.NumberFormat("es-ES", {
  minimumFractionDigits: 2
});

class ChartjsComponent extends Component {
  render() {
    if (!this.props || !this.props.values) {
      return null;
    }

    const { theme } = this.props;

    const options = this.setChartOptions(
      this.props.paddingTop,
      this.props.paddingRight,
      this.props.paddingBottom,
      this.props.paddingLeft
    );
    if (this.props.type === "bar") {
      return (
        <Bar
          data={this.composeChartData(
            this.props.values,
            this.props.labels,
            theme.palette.primary.main,
            theme.palette.primary.main
          )}
          options={options}
        />
      );
    } else {
      return (
        <Line
          data={this.composeChartData(
            this.props.values,
            this.props.labels,
            theme.palette.primary.main,
            "rgb(189, 188, 188, 0.67)"
          )}
          options={options}
        />
      );
    }
  }

  composeChartData(values, labels, borderColor, backgroundColor) {
    const { t } = this.props;
    return {
      labels: labels,
      datasets: [
        {
          label: t("consumptions.chartLabel"),
          data: values,
          backgroundColor: backgroundColor,
          borderColor: borderColor
        }
      ]
    };
  }

  getChartOptions() {
    return {
      manteinAspactRatio: true,
      layout: {
        padding: {
          left: 50,
          right: 50,
          top: 30,
          bottom: 30
        }
      }
    };
  }

  setChartOptions(
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    showLeyend,
    showTitle
  ) {
    return {
      manteinAspactRatio: true,
      layout: {
        padding: {
          left: paddingLeft,
          right: paddingRight,
          top: paddingTop,
          bottom: paddingBottom
        }
      },
      leyend: {
        display: showLeyend
      },
      title: {
        display: showTitle
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";
            if (label) {
              label += ": ";
            }
            label += numberFormat.format(tooltipItem.yLabel);
            return label;
          }
        }
      }
    };
  }
}

export default withTranslation()(withTheme()(ChartjsComponent));
