import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import { CssBaseline } from "@material-ui/core";
import Navigation from "./navigation/navigation";
import Content from "./content/content";
import Header from "./header/header";
import styles from "./layout.styles";
import { connect } from "react-redux";
import { changeAppTitle } from "../../redux/actions/core.actions";
import { withRouter } from "react-router-dom";

function mapDispatchToProps(dispatch) {
  return {
    changeAppTitle: title => dispatch(changeAppTitle(title))
  };
}

const mapStateToProps = state => {
  return { appTitle: state.core.appTitle, user: state.user.user };
};

class LayoutContainer extends Component {
  state = {
    sidebarCollapsed: false
  };

  constructor(props) {
    super(props);

    this.collapseSidebar = this.collapseSidebar.bind(this);
  }

  componentWillMount() {
    this.updateAppTitle(this.props.location.pathname)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.updateAppTitle(this.props.location.pathname)
    }
  }

  updateAppTitle(pathname) {
    const t = this.props.t;

    switch (pathname) {
      case "/":
        this.props.changeAppTitle(t('dashboard.title'));
        break;
      case "/invoices":
        this.props.changeAppTitle(t('invoices.title'));
        break;
      case "/contracts":
        this.props.changeAppTitle(t('contracts.title'));
        break;
      case "/changes":
        this.props.changeAppTitle(t('changes.title'));
        break;
      case "/profile":
        this.props.changeAppTitle(t('profile.title'));
        break;
      case "/consumptions":
        this.props.changeAppTitle(t('consumptions.title'));
        break;
      case "/dataholder":
        this.props.changeAppTitle(t('dataholder.title'));
        break;
      default:
        this.props.changeAppTitle("");
        break;
    }
  }

  collapseSidebar(collapse) {
    this.setState({ sidebarCollapsed: !collapse });
  }

  render() {
    const { classes, appTitle, userName, onLogout } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Header
          appTitle={appTitle}
          userName={userName}
          onLogout={onLogout}
          sidebarCollapsed={this.state.sidebarCollapsed}
          triggerCollapseSidebar={this.collapseSidebar}
        />
        <Navigation
          sidebarCollapsed={this.state.sidebarCollapsed}
          triggerCollapseSidebar={this.collapseSidebar}
        />
        <Content />
      </div>
    );
  }
}

const Layout = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutContainer);

export default withRouter(withTranslation()(withStyles(styles)(Layout)));
