import {
  GET_DATAHOLDER,
  GET_DATAHOLDER_SUCCESS
} from "../constants/action-types";
import { takeLatest, put } from "@redux-saga/core/effects";
import DataholderService from "../../services/dataholder.service";

function* getDataholder(action) {
  const dataholder = yield new DataholderService().get(action.payload);
  yield put({ type: GET_DATAHOLDER_SUCCESS, payload: dataholder });
}

export function* watchDataholder() {
  yield takeLatest(GET_DATAHOLDER, getDataholder);
}
