import React, { Component } from "react";
import Chartjs from "../../components/charting/chartjs/chartjs";
import { connect } from "react-redux";
import { Loading } from "../../components";
import { withTranslation } from "react-i18next";

var moment = require("moment");

const mapStateToProps = state => {
  return {
    invoicesRedux: state.invoices.invoices
  };
};

class ChartContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartValues: [],
      chartLabels: [],
      type: null
    };
  }

  generateChart(consumptions, telemedido) {
    if (telemedido === true) {
      this.generateTelemedidosChartDataset(consumptions);
      this.setState({ type: "line" });
    } else {
      this.generateNoTelemedidosChartDataset(consumptions);
      this.setState({ type: "bar" });
    }
  }

  generateNoTelemedidosChartDataset(consumptions) {
    const values = [];
    const labels = [];

    Array.from(new Set(consumptions.map(s => s.idFactura))).forEach(idFactura => {
      if (!idFactura) return;

      const invoiceConsumption = consumptions
        .filter(c => {
          return c.idFactura === idFactura
        })
        .reduce((a, b) => {
          return a + b.cantidad;
        }, 0);
      values.push(invoiceConsumption);

      const relatedInvoice = this.props.invoices.filter(
        i => i.id === idFactura
      );
      const relatedInvoiceDate = relatedInvoice[0] && relatedInvoice[0].fechaEmision;
      labels.push(relatedInvoiceDate ? new Date(relatedInvoiceDate).toLocaleDateString() : '');
    }
    );

    this.setState({
      chartValues: values,
      chartLabels: labels
    });
  }

  generateTelemedidosChartDataset(consumptions) {
    const values = [];
    const labels = [];

    Array.from(new Set(consumptions.map(s => s.fechaInicio))).map(day => {
      const dailyConsumption = consumptions
        .filter(c => c.fechaInicio === day)
        .reduce((a, b) => {
          return a + b.cantidad;
        }, 0);

      values.push(dailyConsumption);
      labels.push(moment(day).format("DD/MM/YYYY"));
      return null;
    });

    this.setState({
      chartValues: values,
      chartLabels: labels
    });
  }

  componentWillReceiveProps(props) {
    if (props.consumptions && props.consumptions.length > 0) {
      this.generateChart(props.consumptions, props.telemedido);
    } else {
      this.setState({ ...this.state, chartValues: [], chartLabels: [] });
    }
  }

  componentDidMount() {
    if (this.props.consumptions && this.props.consumptions.length > 0) {
      this.generateChart(this.props.consumptions, this.props.telemedido);
    } else {
      this.setState({ ...this.state, chartValues: [], chartLabels: [] });
    }
  }

  render() {
    const { chartValues, chartLabels, type } = this.state;

    if (chartValues && chartValues.length > 0) {
      return <Chartjs values={chartValues} labels={chartLabels} type={type} />;
    } else {
      return (
        <div><br /><br /><Loading /></div>
      );
    }
  }
}

const Chart = connect(mapStateToProps)(ChartContainer);
export default withTranslation()(Chart);
