import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from '@material-ui/core/CardHeader';
import { Link } from "react-router-dom";
import { CardActions, Divider } from "@material-ui/core";
import { Button, Icon } from "@material-ui/core";
import styles from "./consumptionData.styles";
import ChartContainer from "../../../components/charting/chartContainer";
import { Loading } from "../../../components";
import * as consumptionsService from "../../../services/consumptions.service";
import memoizeOne from 'memoize-one';

class ConsumptionData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumptions: null
    }
  }

  componentDidMount() {
    const invoice = this.props.invoice;
    if (!invoice) return;
    this.reloadChart(
      invoice.cups.codCUPS,
      invoice.fechaInicio,
      invoice.fechaFin
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const invoice = this.props.invoice;
    if (!invoice) return;
    this.reloadChart(
      invoice.cups.codCUPS,
      invoice.fechaInicio,
      invoice.fechaFin
    );
  }

  reloadChart = memoizeOne((codCups, initDate, endDate) => {
    if (!codCups || !initDate || !endDate) return;
    return consumptionsService.getAll(codCups, initDate, endDate).then(response => {
      this.setState({
        consumptions: response && response.value ? response.value : []
      });
    });
  });

  render() {
    const { classes, t } = this.props;
    const { invoice, telemedido, invoices } = this.props;
    const { consumptions } = this.state

    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Icon color="secondary" style={{ fontSize: 50 }}>
              bar_chart
            </Icon>
          }
          title={t('dashboard.consumptionsHeader')}
          titleTypographyProps={{ variant: "h5" }}
        />
        <CardContent className={classes.cardContent}>
          <div className={classes.cardData}>
            {consumptions && invoices && telemedido !== null ? (
              <ChartContainer
                telemedido={telemedido}
                consumptions={consumptions}
                invoices={invoices}
              />
            ) : (
                <Loading />
              )}
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions} disableActionSpacing>
          {invoice && (
            <Button
              color="primary"
              component={Link}
              to={`/consumptions/${invoice.contrato.codContrato}/${
                invoice.cups.codCUPS
                }/${invoice.fechaInicio}/${invoice.fechaFin}`}
            >
              {t('dashboard.consumptionsShowActionLabel')}
            </Button>
          )}
        </CardActions>
      </Card>
    );
  }
}

export default withTranslation()(withStyles(styles)(ConsumptionData));
