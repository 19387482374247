export function getToken(idToken) {
  // if (process.env.NODE_ENV === 'development') {
  //   return {
  //     access_token:
  //       'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IlNzWnNCTmhaY0YzUTlTNHRycFFCVEJ5TlJSSSIsImtpZCI6IlNzWnNCTmhaY0YzUTlTNHRycFFCVEJ5TlJSSSJ9.eyJhdWQiOiJodHRwczovL2dsb2JhbGVyaXMub25taWNyb3NvZnQuY29tL09EYXRhU2VydmljZSIsImlzcyI6Imh0dHBzOi8vc3RzLndpbmRvd3MubmV0LzM2M2IyMjExLWY3ZmQtNDBlMy1hNGQyLWYyMjFmOTkxNjA1MC8iLCJpYXQiOjE1OTEyNjgzMzgsIm5iZiI6MTU5MTI2ODMzOCwiZXhwIjoxNTkxMjcyMjM4LCJhaW8iOiI0MmRnWUlqZU8yL04wM01CYWkrM2Q5NjIzTVU0Q3dBPSIsImFwcGlkIjoiMDFmNWUzMTctZGQ5Zi00NGY4LWIyZTUtZmYzNTMwMGY5NTVjIiwiYXBwaWRhY3IiOiIxIiwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvMzYzYjIyMTEtZjdmZC00MGUzLWE0ZDItZjIyMWY5OTE2MDUwLyIsIm9pZCI6IjllNDgxNzllLWQwM2QtNGJlNS04NWEyLWFkZWQ5OGNiZDIzYSIsInJvbGVzIjpbIkFsbCJdLCJzdWIiOiI5ZTQ4MTc5ZS1kMDNkLTRiZTUtODVhMi1hZGVkOThjYmQyM2EiLCJ0aWQiOiIzNjNiMjIxMS1mN2ZkLTQwZTMtYTRkMi1mMjIxZjk5MTYwNTAiLCJ1dGkiOiJrRklkS2hkQ3IwT0ZsajhSXzJjZ0FBIiwidmVyIjoiMS4wIn0.f7Qw3afigErPUnuzPx5QOj59NGMOROXjbDW2Z8dtQoWxDIBkdlSJgxHzyBy3BnxffYhLa9FH4_9X4S1JL5U-NMLKcFJ6v8osV-0m4utXIgBc_8IsbLyn8Bb0SWIJdA6V_owIkuYD99pslzRdLj6f2pzJplXkbPwyOLpKlEROPM0Kh8mQEtQaUL6sxkSY6k85DWevsej8UzXiCmkmyv3mbbv5VzHHbY0A19ymhZLZaVOFw3ODSyhgnrSa2GKf2rG4RLWCil3vQk5Cip88fItTb3oiM0eHQzYA_plUHL0MbxNTnxXf0yGwPPFmSMTahQxdv-p33jtMUUEJAA7D4EcleA'
  //   };
  // }

  const host = window.location.href.split('/').slice(0, 3).join('/');
    return fetch(`${host}/api/oDataAuth/GetAuthToken`,
     {
        headers: {
             Authorization: 'Bearer ' + idToken //sessionStorage.getItem('msal.idtoken')
        }
    })
    .then(
      response => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      },
      error => {
        console.log('oDataAuth.getToken()', error);
      }
    )
    .catch(error => {
      console.log('oDataAuth.getToken()', error);
    });
}
