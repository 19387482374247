import React from "react";
import { connect } from "react-redux";
import {
  Hidden,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar
} from "@material-ui/core";
import * as ErrorHelper from "../../helpers";

const mapStateToProps = state => {
  return {
    message: state.core.error.message,
    onCloseHandler: state.core.error.onCloseHandler,
    title: state.core.error.title,
    action: state.core.error.action
  };
};

const MessageBoxContainer = (props) => {

  const handleClose = () => {
    const { onCloseHandler } = props;
    if (onCloseHandler !== null) {
      onCloseHandler();
    }
    ErrorHelper.resetError();
  }

  const { message, title, action } = props;

  if (!message) {
    return null;
  }

  return (
    <>
      <Hidden only={["sm", "md", "lg", "xl"]}>
        <Snackbar
          open
          onClose={handleClose}
          ContentProps={{
            "aria-describedby": "snackbar-fab-message-id"
          }}
          message={<span id="snackbar-fab-message-id">{message}</span>}
          action={
            <Button color="inherit" size="small" onClick={handleClose}>
              {action}
            </Button>
          }
        />
      </Hidden>
      <Hidden only={["xs"]}>
        <Dialog
          open
          fullWidth
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              {action}
            </Button>
          </DialogActions>
        </Dialog>
      </Hidden>
    </>
  );
}

export default connect(mapStateToProps)(MessageBoxContainer);
