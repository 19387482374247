import {
  CHANGE_APP_TITLE,
  SET_LOADING,
  SET_ERROR
} from "../constants/action-types";

const initialState = {
  appTitle: "Bienvenidos",
  error: "",
  isLoading: false
};

function coreReducer(state = initialState, action) {
  if (action.type === CHANGE_APP_TITLE) {
    return Object.assign({}, state, {
      appTitle: action.payload
    });
  }
  if (action.type === SET_LOADING) {
    return Object.assign({}, state, {
      isLoading: action.payload
    });
  }
  if (action.type === SET_ERROR) {
    return Object.assign({}, state, {
      error: action.payload
    });
  }
  return state;
}
export default coreReducer;
