import { drawerWidth } from "../navigation/navigation.styles";

export default theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  appBarTitle: {
    flex: 1
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  icon: {
    color: "#ffffff"
  },
  menuItemRoot: {
    "&:hover": {
      background: "none",
      "& span": {
        color: theme.palette.primary.main
      },
      "& svg": {
        fill: theme.palette.primary.main
      }
    }
  }
});
