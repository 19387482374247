import KEYS_BY_HOST from '../policyKeys.json';
import { getAppConfigurationKey } from '../helpers/appConfigurationHelper';

export function getHostFromURL() {
  return window.location.hostname;
}

export function getHostPrefix() {
  const fullHost = getHostFromURL();
  const hostPrefix = fullHost.split('-') && fullHost.split('-')[0];
  return hostPrefix;
}
export function getHostClientFromURL() {
    return window.location.hostname.split('.')[0];
}
function getKeysForCurrentHost() {
  const urlHost = getHostFromURL();
  return KEYS_BY_HOST[urlHost];
}

export function getForgotPasswordURL() {
  return getKeysForCurrentHost().forgotPasswordUrl;
}

export function getApimUrl(prefixEnvironment) {

    return getAppConfigurationKey("Host:ApiManagement") + getAppConfigurationKey(prefixEnvironment);
}
