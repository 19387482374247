import { FETCH_CUPS_SUCCESS, SELECT_CUPS } from '../constants/action-types';

const initialState = {
  cups: [],
  selectedCups: null
};

function cupsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CUPS_SUCCESS:
      return Object.assign({}, state, {
        cups: action.payload,
        selectedCups: action.payload && action.payload.length ? action.payload[0] : null
      });
    case SELECT_CUPS:
      return Object.assign({}, state, {
          selectedCups: (state.cups && state.cups.length > 0)
              ? state.cups.find((e) => e && e.id && e.id.toString() === action.payload.toString())
              : null
      });

    default:
      return state;
  }
}
export default cupsReducer;
