import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden'
  },
  spinnerContainer: {
    textAlign: 'center'
  },
  noDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h6': {
      opacity: 0.2
    },
    '& svg': {
      opacity: 0.1,
      fontSize: '60px'
    }
  }
};

const LoadingInner = props => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const delayLoadingState = async () => {
      await new Promise(resolve => setTimeout(resolve, 6000));
      setIsLoading(false);
    };
    delayLoadingState();
  }, []);

  const classes = props.classes;
  const circularProgress = <CircularProgress color={props.color} size={props.size} />;

  if (!isLoading) {
    return (
      <div className={classes.noDataContainer}>
        <SentimentDissatisfiedIcon />
        <Typography variant="h6">sin datos</Typography>
      </div>
    );
  }

  if (isLoading) {
    return props.modal ? (
      <Dialog
        open
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          classes: {
            root: classes.paper
          }
        }}
      >
        <div>{circularProgress}</div>
      </Dialog>
    ) : (
      <div className={classes.spinnerContainer}>{circularProgress}</div>
    );
  }
};

LoadingInner.defaultProps = {
  modal: false,
  color: 'primary',
  size: 40
};

export const Loading = withStyles(styles)(LoadingInner);
