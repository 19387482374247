import { createStore, applyMiddleware } from "redux";
import reducers, { initialState } from "../reducers/index";
import sagaWatchers from '../sagas/index';
import { Logger } from "../middleware/logger";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(Logger, sagaMiddleware))
);

sagaMiddleware.run(sagaWatchers);

export default store;
