import React, { Component, Fragment } from "react";
import { withTranslation } from 'react-i18next';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {
  Icon,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from "@material-ui/core";
import { Link } from "react-router-dom";

class InvoiceActionsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorActionsMenu: null
    };

    this.handleActionsClose = this.handleActionsClose.bind(this);
    this.handleActionsClick = this.handleActionsClick.bind(this);
  }

  handleActionsClose() {
    this.setState({ anchorActionsMenu: null });
  }

  handleActionsClick(event) {
    this.setState({ anchorActionsMenu: event.currentTarget });
  }

  triggerHandleRequestFileDownload(invoiceCode, format) {
    this.props.handleRequestFileDownload(invoiceCode, format);
  }

  render() {
    const { invoice, t } = this.props;
    const { anchorActionsMenu } = this.state;

    return (
      <Fragment>
        <IconButton
          aria-owns={anchorActionsMenu ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleActionsClick}
        >
          <Icon color="secondary">more_vert</Icon>
        </IconButton>
        <Menu
          id="simple-menu"
          keepMounted
          anchorEl={anchorActionsMenu}
          open={Boolean(anchorActionsMenu)}
          onClose={this.handleActionsClose}
        >
          <MenuItem
            button
            onClick={() => {
              this.handleActionsClose();
              this.triggerHandleRequestFileDownload(invoice.codFactura, "pdf");
            }}
          >
            <ListItemIcon>
              <PictureAsPdfIcon color="secondary" />
            </ListItemIcon>
            <ListItemText inset primary={t('invoices.downloadPdfActionLabel')} />
          </MenuItem>
          {/* <MenuItem button onClick={() => {this.handleRequestFileDownload(invoice.codFactura, 'xml')}}>
                            <ListItemIcon>
                              <Icon>arrow_downward</Icon>
                            </ListItemIcon>
                            <ListItemText inset primary="Descargar XML" />
                          </MenuItem> */}
          <MenuItem
            button
            onClick={() => this.handleActionsClose()}
            component={Link}
            to={`/consumptions/${invoice.contrato.id}/${invoice.cups.codCUPS}/${
              invoice.fechaInicio
              }/${invoice.fechaFin}`}
          >
            <ListItemIcon>
              <Icon color="secondary">bar_chart</Icon>
            </ListItemIcon>
            <ListItemText inset primary={t('invoices.showConsumptionsActionLabel')} />
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

export default withTranslation()(InvoiceActionsMenu);
