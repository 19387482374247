export default theme => ({
  card: {
    maxWidth: 350
  },
  cardHeader: {
    textAlign: "right",
    marginBottom: "-22px"
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 143
  },
  cardData: {
    textAlign: "right",
    width: "100%"
  },
  cardInfo: {
    textAlign: "right"
  }
});
