import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import styles from "./dataholderInformation.styles";

class DataholderPhysicalTypeInformation extends Component {
  state = {};

  render() {
    const { dataholder } = this.props;

    return (
      <form>
        <Grid container>
          <Grid item xs>
            <TextField
              id="dataholder-name"
              label="Nombre"
              margin="normal"
              value={dataholder.nombre}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-surname1"
              label="Primer apellido"
              margin="normal"
              value={dataholder.apellido1}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-surname2"
              label="Segundo apellido"
              margin="normal"
              value={dataholder.apellido2}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <TextField
              id="dataholder-identification-number"
              label="NIF"
              margin="normal"
              value={dataholder.documentoFiscal}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs>
            <TextField
              id="dataholder-address"
              label="Dirección"
              margin="normal"
              value={dataholder.direccion.direccion}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-postal-code"
              label="Código postal"
              margin="normal"
              value={dataholder.direccion.direccionCP}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-location"
              label="Municipio"
              margin="normal"
              value={dataholder.direccion.direccionMunicipio}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-province"
              label="Provincia"
              margin="normal"
              value={dataholder.direccion.direccionProvincia}
              disabled
            />
          </Grid>
          <Grid item xs>
            <TextField
              id="dataholder-country"
              label="Pais"
              margin="normal"
              value={dataholder.direccion.direccionPais}
              disabled
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withStyles(styles)(DataholderPhysicalTypeInformation);
