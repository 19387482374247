import * as ErrorHelper from '../helpers';
import i18n from '../i18n';
import { getApimUrl } from '../helpers/configHelper';

export function getAll(userId) {
  return fetch(
    // "https://api.globalsyde.com/saas-facturas/odata/Facturas?api-version=1.0&$orderby=fechaInicio desc&$expand=Contrato&$filter=IdSujetoDestino eq " +
    //   userId
      getApimUrl("PrefixEnviromentFacturas") +
      "/odata/Facturas?api-version=1.0&$orderby=fechaInicio desc&$expand=SujetoDestino,Contrato,ResumenFactura,CUPS&$filter=SujetoDestino/id eq " +
      userId + " and tipoFactura eq '1' and estadoFactura eq '2'"
  )
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .catch(error => {
      ErrorHelper.setError(i18n.t('messages.noInvoicesFoundForCustomer', { userId }));
      console.error('IvoiceService.getAll()', error);
    });
}
