export const drawerWidth = 240;

export default theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  logo: {
    width: "239px",
    height: "64px",
    backgroundImage: `url(${theme.logoBase64})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  logoPlaceholder: {
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  drawerPaper: {
    width: drawerWidth
  },
  listItemRoot: {
    "&.active, &:hover, &.active:hover": {
      background: "none",
      "& span": {
        color: theme.palette.primary.main
      },
      "& svg": {
        fill: theme.palette.primary.main
      }
    }
  }
});
