import { GET_USER, GET_USER_SUCCESS } from '../constants/action-types';
import { takeLatest, put } from '@redux-saga/core/effects';
import AAD_B2CService from '../../services/AAD_B2C.service';
import dataholderService from '../../services/dataholder.service';
import * as ErrorHelper from '../../helpers';
import i18n from '../../i18n';
import { getHostClientFromURL } from '../../helpers';

function* getUser(action) {
  const authService = new AAD_B2CService();
  const email = yield authService.getUser().email;
  const user = yield new dataholderService().getByEmail(email);
  const urlHostClient = getHostClientFromURL();
  const authHost = authService.getAuthHostClient();

  // user must be logged in an allowed host
  if (authHost !== urlHostClient || !user || !user.value[0]) {
    const errorMsg = i18n.t('messages.noCustomerDataFoundByEmail', { email });
    ErrorHelper.setError(
      errorMsg,
      () => {
        authService.logout();
      },
      'Aviso',
      'SALIR'
    );
    throw Error(errorMsg);
  }

  yield put({
    type: GET_USER_SUCCESS,
    payload: { userId: user && user.value[0] && user.value[0].id, email: email }
  });
}

export function* watchUser() {
  yield takeLatest(GET_USER, getUser);
}
