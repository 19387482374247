import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import styles from "./navigation.styles";
import { Hidden, Divider } from "@material-ui/core/";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  handleDrawerToggle() {
    this.props.triggerCollapseSidebar(true);
  }

  render() {
    const { classes, container, sidebarCollapsed, t } = this.props;

    return (
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={sidebarCollapsed}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            <div>
              <div
                className={classNames(classes.toolbar, classes.logoPlaceholder)}
              >
                <div className={classes.logo} />
              </div>
              <div>
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  exact
                  to="/"
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.home')} />
                </ListItem>
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  to="/consumptions"
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.comsumptions')} />
                </ListItem>
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  to="/invoices"
                >
                  <ListItemIcon>
                    <LayersIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.invoices')} />
                </ListItem>
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  to="/contracts"
                >
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.contracts')} />
                </ListItem>
                {/* <ListItem 
                className={classes.listItemRoot}
                button component={NavLink} to="/changes">
                  <ListItemIcon>
                    <Icon>compare_arrows</Icon>
                  </ListItemIcon>
                  <ListItemText primary="Cambios" />
                </ListItem> */}
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  to="/profile"
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.profile')} />
                </ListItem>
              </div>
            </div>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            <div>
              <div
                className={classNames(classes.toolbar, classes.logoPlaceholder)}
              >
                <div className={classes.logo} />
              </div>
              <Divider />
              <div className="navigation__items">
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  exact
                  to="/"
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.home')} />
                </ListItem>
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  to="/consumptions"
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.comsumptions')} />
                </ListItem>
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  to="/invoices"
                >
                  <ListItemIcon>
                    <LayersIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.invoices')} />
                </ListItem>
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  to="/contracts"
                >
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.contracts')} />
                </ListItem>
                {/* <ListItem 
                  className={classes.listItemRoot}                
                  button component={NavLink} to="/changes">
                    <ListItemIcon>
                      <Icon>compare_arrows</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Cambios" />
                  </ListItem> */}
                <ListItem
                  className={classes.listItemRoot}
                  button
                  onClick={this.handleDrawerToggle}
                  component={NavLink}
                  to="/profile"
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('navigation.profile')} />
                </ListItem>
              </div>
              <div style={{ color: "#fff", padding: "20px" }}>
                {process.env.REACT_APP_CONFIG_NAME}
              </div>
            </div>
          </Drawer>
        </Hidden>
      </nav >
    );
  }
}

export default withTranslation()(withStyles(styles)(Navigation));
