import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using xhr -> see /public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: "es",
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })
  .then(t => {
    document.title = t("app.title");
  });

export default i18n;
