import {
  FETCH_INIT_DATA,
  GET_USER,
  FETCH_CONTRACTS,
  FETCH_INVOICES,
  SET_LOADING,
  GET_DATAHOLDER
} from "../constants/action-types";
import { takeLatest, put, select, delay } from "@redux-saga/core/effects";

export const selectedUser = (state) => state.user.user;


function* fetchInitData(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    yield put({ type: GET_USER, payload: action.payload });

    while (true) {

      const user = yield select(selectedUser);

      if (user.userId !== null) {
        yield put({ type: FETCH_CONTRACTS, payload: user.userId });
        yield put({ type: FETCH_INVOICES, payload: user.userId });
        yield put({ type: GET_DATAHOLDER, payload: user.userId });
        yield put({ type: SET_LOADING, payload: false });

        break;
      }
      else {
        yield delay(1000);
      }
    }
  } catch (e) {
    console.log('ERROR fetchInitData', e);
  }
}

export function* watchCore() {
  yield takeLatest(FETCH_INIT_DATA, fetchInitData);
}
