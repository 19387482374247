import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig"; 
const msalInstance = new PublicClientApplication(msalConfig);

export default class AAD_B2CService {
   
    loggedIn() {
        return true;
    }

    logout() { 
        msalInstance.logoutRedirect();
    } 
    getUser() {
        try
        {
            const account = msalInstance.getAllAccounts()[0];
           
            return {
                email: account.idTokenClaims && account.idTokenClaims['signInNames.emailAddress']
                
            };
        } catch (error) {
            console.error('getUser() error', error);
            return null;
        }
    }


    getAuthHost() {
        try {
            const account = msalInstance.getAllAccounts()[0];
            
            if (process.env.NODE_ENV === 'development')
              return 'localhost';
            return account.idTokenClaims && account.idTokenClaims['extension_Host'];
        } catch (error) {
            console.error('getAuthHost() error', error);
            return null;
        }
    }
    getAuthHostClient() {
        try {
            const account = this.getAuthHost(); 
            return account.split('.')[0];
        } catch (error) {
            console.error('getAuthHostClient() error', error);
            return null;
        }
    }
}
