import store from "../redux/store/index";
import { SET_ERROR } from "../redux/constants/action-types";
import i18n from "../i18n";

export function setError(
  message,
  onCloseHandler = null,
  title = i18n.t("messages.defaultErrorTitle"),
  action = i18n.t("messages.defaultErrorAction")
) {
  store.dispatch({
    type: SET_ERROR,
    payload: { message, onCloseHandler, title, action }
  });
}

export function resetError() {
  store.dispatch({ type: SET_ERROR, payload: { message: "", callback: null } });
}
