import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Dialog, DialogContent } from "@material-ui/core";

function WithLoading(Component) {
  return function WihLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return (
      <Dialog onClose={null} open={true}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  };
}
export default WithLoading;
