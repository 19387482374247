import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {
  Hidden,
  FormControl,
  Grid,
  TextField
} from "@material-ui/core/";
import memoizeOne from 'memoize-one';
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { InputAdornment, Icon } from "@material-ui/core";
import "moment/locale/es";
import { withTranslation } from 'react-i18next';

import * as consumptionsService from "../../services/consumptions.service";
import { fetchCups, selectCups } from "../../redux/actions/cups.actions";
import { selectContract } from "../../redux/actions/contracts.actions";
import ChartContainer from "../../components/charting/chartContainer";
import { Loading } from "../../components";
import styles from "./consumptionsContainer.styles";

const mapStateToProps = state => {
  return {
    appTitle: state.core.appTitle,
    contracts: state.contracts.contracts,
    selectedContract: state.contracts.contract,
    cups: state.cups.cups,
    user: state.user.user,
    invoices: state.invoices.invoices
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCups: contractId => dispatch(fetchCups(contractId)),
    selectContract: contractId => dispatch(selectContract(contractId)),
    selectCups: cupsId => dispatch(selectCups(cupsId))
  };
}

function parseDate(dateToParse, separator = "-") {
  let newDate = new Date(dateToParse);
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

class ConsumptionsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initDate: props.match.params ? props.match.params.initDate : null,
      endDate: props.match.params ? props.match.params.endDate : null,
      currentCups: props.match.params ? props.match.params.cups : undefined,
      currentContract: props.match.params ? props.match.params.contract : null,
      availableCups: null,
      availableInvoices: null,
      consumptions: null,
      telemedido: null
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const currentContract = nextProps.selectedContract;
    const allCups = prevState.availableCups || nextProps.cups;

    const defaultCupsForCurrentContract = allCups && allCups[0] && allCups[0].codCUPS;
    const selectedCups = prevState.currentCups;
    const selectedCupsBelongsToCurrentContract = selectedCups && allCups && allCups.find(cups => cups.codCUPS === prevState.currentCups);

    // uses the selected CUPS if it belongs to the selected contract, otherwise uses the default CUPS for the selected contract
    const currentCups = selectedCupsBelongsToCurrentContract ? selectedCups : defaultCupsForCurrentContract;

    const cups = allCups && allCups.filter(
      c => c.codCUPS === currentCups
    )[0];
    const telemedido = cups && cups.telemedido

    let firstInvoiceInitDate = null;
    let lastInvoiceEndDate = null;
    if (prevState.initDate == null && prevState.endDate == null) {
      const contractInvoices = nextProps.invoices.filter(
        c => c.contrato.id === (nextProps.selectedContract && nextProps.selectedContract.id)
      );

      if (contractInvoices && contractInvoices.length) {
        firstInvoiceInitDate = parseDate(
          contractInvoices[contractInvoices.length - 1].fechaInicio
        );
        lastInvoiceEndDate = parseDate(contractInvoices[0].fechaFin);
      }
    }

    let availableInvoices = null;
    if (prevState.availableInvoices && prevState.availableInvoices.length) {
      availableInvoices = prevState.availableInvoices
    } else if (nextProps.invoices && nextProps.invoices.length) {
      availableInvoices = nextProps.invoices
    }

    return {
      initDate: prevState.initDate || firstInvoiceInitDate,
      endDate: prevState.endDate || lastInvoiceEndDate,
      availableInvoices,
      telemedido,
      currentCups,
      currentContract
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.initDate !== prevState.initDate ||
      this.state.endDate !== prevState.initDate ||
      this.state.currentCups !== prevState.currentCups
    ) {
      this.reloadChart(
        this.state.currentCups,
        this.state.initDate,
        this.state.endDate
      );
    }
  }

  reloadChart = memoizeOne((codCups, initDate, endDate) => {
    return consumptionsService.getAll(codCups, initDate, endDate).then(response => {
      this.setState({
        consumptions: response && response.value ? response.value : undefined
      });
    });
  });

  handleContractChange = (e) => {
    const contractId = e.target.value;
    this.setState({ currentCups: undefined }); // forces to use the default cups for the new selected contract.
    this.props.selectContract(contractId);
  }

  handleCUPSChange = (e) => {
    this.setState({ currentCups: e.target.value })
  }

  handleInitDateChange(date) {
    this.setState(
      { initDate: parseDate(date.toDate()) }
    );
  }

  handleEndDateChange(date) {
    this.setState(
      { endDate: parseDate(date.toDate()) }
    );
  }

  render() {
    const {
      initDate,
      endDate,
      currentCups,
      currentContract,
      consumptions,
      telemedido,
      availableInvoices
    } = this.state;
    const { classes, appTitle, contracts, cups, t } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.title}>
            <Hidden smDown implementation="css">
              <Typography variant="h5" gutterBottom component="h2">
                {appTitle}
              </Typography>
            </Hidden>
          </div>
          <div className={classes.filters}>
            <Grid container spacing={24}>
              {contracts && contracts.length > 0 && (
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <TextField
                      select
                      variant="outlined"
                      margin="dense"
                      id="contract"
                      inputProps={{
                        name: "contract",
                        id: "contract"
                      }}
                      inputlabelprops={{
                        shrink: "true"
                      }}
                      label={t('consumptions.contractInputLabel')}
                      value={currentContract && currentContract.id}
                      onChange={this.handleContractChange}
                      SelectProps={{
                        native: true
                      }}
                    >
                      {contracts.map((dt, i) => {
                        return (
                          <option key={i} value={dt.id}>
                            {dt.codContrato} - {dt.descripcion}
                          </option>
                        );
                      })}
                    </TextField>
                  </FormControl>
                </Grid>
              )}
              <Grid item>
                <FormControl className={classes.formControl}>
                  <TextField
                    select
                    variant="outlined"
                    margin="dense"
                    id="cups"
                    inputProps={{
                      name: "cups",
                      id: "cups"
                    }}
                    inputlabelprops={{
                      shrink: "true"
                    }}
                    label={t('consumptions.cupsInputLabel')}
                    value={currentCups ? currentCups : " "}
                    onChange={this.handleCUPSChange}
                    SelectProps={{
                      native: true
                    }}
                  >
                    {cups && cups.map((dt, i) => {
                      return (
                        <option key={i} value={dt.codCUPS}>
                          {dt.codCUPS}
                        </option>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </div>

        <Paper className={classes.chartPaper}>
          <Grid className={classes.chartFilters} container spacing={24}>
            {/* <Grid item>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.b80}
                      //onChange={this.handleSwitchChange("b80")}
                      value="b80"
                      color="secondary"
                    />
                  }
                  label="Facturado"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.b70}
                      //onChange={this.handleSwitchChange("b70")}
                      value="b70"
                      color="primary"
                    />
                  }
                  label="Nominado"
                  labelPlacement="top"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.b70}
                      //onChange={this.handleSwitchChange("b70")}
                      value="b70"
                      color="primary"
                    />
                  }
                  label="Contractual"
                  labelPlacement="top"
                />
              </FormGroup>
            </Grid> */}
            <Grid item xs={6} md={2}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"es"}>
                <DatePicker
                  disabled={!consumptions || telemedido === null || !availableInvoices}
                  variant="outlined"
                  id="initDate"
                  name="initDate"
                  autoOk={true}
                  label={t('consumptions.initDateInputLabel')}
                  cancelLabel={t('consumptions.initDateCancelLabel')}
                  openTo="month"
                  value={initDate}
                  onChange={date => this.handleInitDateChange(date)}
                  format="DD/MM/YYYY"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="secondary">today</Icon>
                      </InputAdornment>
                    ),
                    shrink: "true"
                  }}
                  maxDate={endDate}
                  maxDateMessage="Debe ser inferior a la fecha fin"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} md={2}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale={"es"}>
                <DatePicker
                  disabled={!consumptions || telemedido === null || !availableInvoices}
                  variant="outlined"
                  id="endDate"
                  name="endDate"
                  autoOk={true}
                  label={t('consumptions.endDateInputLabel')}
                  cancelLabel={t('consumptions.endDateCancelLabel')}
                  openTo="month"
                  value={endDate}
                  onChange={date => this.handleEndDateChange(date)}
                  format="DD/MM/YYYY"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon color="secondary">today</Icon>
                      </InputAdornment>
                    ),
                    shrink: "true"
                  }}
                  minDate={initDate}
                  minDateMessage="Debe ser superior a la fecha inicio"
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          {consumptions && telemedido !== null && availableInvoices ? (
            <ChartContainer
              consumptions={consumptions}
              telemedido={telemedido}
              invoices={availableInvoices}
            />
          ) : (
              <div className={classes.loadingContainer}>
                <Loading />
              </div>
            )}
        </Paper>
      </div>
    );
  }
}

const Consumptions = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumptionsContainer);
export default withTranslation()(withStyles(styles)(Consumptions));
