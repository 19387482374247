import * as ErrorHelper from '../helpers';
import i18n from '../i18n';
import { getApimUrl } from '../helpers/configHelper';

export default class DataholderService {
  get(dataholderId) {
      return fetch(
        getApimUrl("PrefixEnviromentSujetos") +
        '/odata/Sujetos(' +
        dataholderId +
        ')?api-version=1.0&$expand=contactos'
    ).then(
      response => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      },
      error => {
        ErrorHelper.setError(i18n.t('messages.noCustomerDataFoundById', { dataholderId }));
        console.error('DataholderService.get()', error);
      }
    );
  }

  getByEmail(email) {
    return fetch(
        `${getApimUrl("PrefixEnviromentSujetos")}/odata/Sujetos?api-version=1.0&$expand=contactos&$filter=contactos/any() and contactos/any(d: d/email eq '${email}')`
    ).then(
      response => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      },
      error => {
        ErrorHelper.setError(i18n.t('messages.noCustomerDataFoundByEmail', { email }));
        console.error('DataholderService.getByEmail()', error);
      }
    );
  }
}
