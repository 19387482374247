import fetchIntercept from 'fetch-intercept';
import { getAppConfigurationKey } from '../helpers/appConfigurationHelper';

export function initializeHttpInterceptors() {
  fetchIntercept.register({
    request: function (url, config) {
      if (url.indexOf('oDataAuth/GetAuthToken') !== -1) {
        //config = {
        //  headers: {
        //    Authorization: 'Bearer '  + sessionStorage.getItem('msal.idtoken')
        //  }
        //};
      } else {
        if (url.indexOf('odata') !== -1) {
          config = {
              headers: {
                  'Ocp-Apim-Subscription-Key': getAppConfigurationKey("AreaPrivada:Subscription"),
                  Authorization: 'Bearer ' + localStorage.getItem('oDataAuthToken')
              }
          };
        } else {
          config = {
              headers: {
                  'Ocp-Apim-Subscription-Key': getAppConfigurationKey("AreaPrivada:Subscription"),
                  Authorization: 'Bearer ' + localStorage.getItem('oDataAuthToken'),
                  
              }
          };
        }
      }
      return [url, config];
    },

    requestError: function (error) {
      return Promise.reject(error);
    },

    response: function (response) {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    },

    responseError: function (error) {
      return Promise.reject(error);
    }
  });
}

