import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { Switch, Route } from "react-router-dom";
import DashboardContainer from "../../../screens/dashboard/dashboardContainer";
import DataholderContainer from "../../../screens/dataholder/dataholderContainer";
import InvoicesContainer from "../../../screens/invoices/invoicesContainer";
import ConsumptionsContainer from "../../../screens/consumptions/consumptionsContainer";
import ContractsContainer from "../../../screens/contracts/contractsContainer";
import ProfileContainer from "../../../screens/profile/profileContainer";
import ChangesContainer from "../../../screens/changes/changesContainer";
import MessageBox from "../../messageBox/messageBoxContainer";

import styles from "./content.styles";

class Content extends Component {

  render() {
    const { classes } = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <MessageBox></MessageBox>
        <Switch>
          <Route exact path="/" component={DashboardContainer} />
          <Route exact path="/dataholder" component={DataholderContainer} />
          <Route exact path="/invoices" component={InvoicesContainer} />
          <Route exact path="/consumptions" component={ConsumptionsContainer} />
          <Route exact path="/consumptions/:contract/:cups/:initDate/:endDate" component={ConsumptionsContainer} />
          <Route exact path="/contracts" component={ContractsContainer} />
          <Route exact path="/profile" component={ProfileContainer} />
          <Route exact path="/changes" component={ChangesContainer} />
          <Route exact path="*" component={DashboardContainer} />
        </Switch>
      </main>
    );
  }
}

export default withStyles(styles)(Content);
