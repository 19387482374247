import { FETCH_CONTRACTS_SUCCESS, SELECT_CONTRACT_SUCCESS } from '../constants/action-types';

const initialState = {
  contracts: [],
  contract: null
};

function contractsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTRACTS_SUCCESS:
      return Object.assign({}, state, {
        contracts: action.payload,
        contract: action.payload && action.payload.length ? action.payload[0] : null
      });
    case SELECT_CONTRACT_SUCCESS:
      return Object.assign({}, state, {
        contract: state.contracts
          ? state.contracts.find(e => e.id.toString() === action.payload.toString())
          : null
      });
    default:
      return state;
  }
}
export default contractsReducer;
