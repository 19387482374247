import { getApimUrl } from '../helpers/configHelper';
var moment = require('moment');

export async function getAll(cups, fromDate, toDate) {
  // const initDate = fromDate.indexOf('T') === -1 ? `${fromDate}T00:00:00Z` : fromDate;
  // const endDate = toDate.indexOf('T') === -1 ? `${toDate}T00:00:00Z` : toDate;

  const initDate = moment(fromDate).format('YYYY-MM-DD') + 'T00:00:00Z';
  const endDate = moment(toDate).format('YYYY-MM-DD') + 'T00:00:00Z';

    return fetch(
        getApimUrl("PrefixEnviromentMedidas") +
        "/odata/Medidas?api-version=1.0&$expand=documento, puntoSuministro($expand=CUPS)&$orderby=fechaInicio&$filter=tipoMedida eq  '1' and  documento/tipoDocumento eq 'FacturaCliente' and puntosuministro/cups/codCUPS eq '" +
      cups +
      "' and fechaInicio ge " +
      initDate +
      ' and fechaInicio le ' +
      endDate
  )
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .catch(error => {
      console.error('ConsumptionsService.getAll()', error);
    });
}
