import React, { Component, Fragment } from "react";
import { withTranslation } from 'react-i18next';
import {
  Icon,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from "@material-ui/core";

class ContractActionsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorActionsMenu: null
    };

    this.handleActionsClose = this.handleActionsClose.bind(this);
    this.handleActionsClick = this.handleActionsClick.bind(this);
    this.handleNavigationToInvoices = this.handleNavigationToInvoices.bind(
      this
    );
  }

  handleActionsClose() {
    this.setState({ anchorActionsMenu: null });
  }

  handleActionsClick(event) {
    this.setState({ anchorActionsMenu: event.currentTarget });
  }

  handleNavigationToInvoices(selectedContract) {
    this.props.triggerHandleNavigationToInvoices(selectedContract);
  }

  render() {
    const { contract, t } = this.props;
    const { anchorActionsMenu } = this.state;
    return (
      <Fragment>
        <IconButton
          aria-owns={anchorActionsMenu ? "simple-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleActionsClick}
        >
          <Icon color="secondary">more_vert</Icon>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorActionsMenu}
          open={Boolean(anchorActionsMenu)}
          onClose={this.handleActionsClose}
        >
          {/* <MenuItem button component={Link} to="/profile">
                              <ListItemIcon>
                                <Icon>edit</Icon>
                              </ListItemIcon>
                              <ListItemText inset primary="Modificar" />
                            </MenuItem>
                            <MenuItem button component={Link} to="/profile">
                              <ListItemIcon>
                                <PictureAsPdfIcon />
                              </ListItemIcon>
                              <ListItemText inset primary="Descargar en PDF" />
                            </MenuItem> */}
          <MenuItem
            button
            onClick={() => this.handleNavigationToInvoices(contract.id)}
          >
            <ListItemIcon>
              <Icon color="secondary">file_copy</Icon>
            </ListItemIcon>
            <ListItemText inset primary={t('contracts.showInvoicesActionLabel')} />
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

export default withTranslation()(ContractActionsMenu);
