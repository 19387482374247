import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import DataholderInformation from "./dataholderInformation/dataholderInformation";
import ContactInformation from "./contactInformation/contactInformacion";
import PaymentMethods from "./paymentMethods/paymentMethods";
import WithLoading from "../../components/hoc/withLoading";
import DataholderService from "../../services/dataholder.service";
import * as contractsService from "../../services/contracts.service";
import { connect } from "react-redux";


const mapStateToProps = state => {
  return { appTitle: state.core.appTitle };
};

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit
    }
  }
});

const DataholderInformationWithLoading = WithLoading(DataholderInformation);
const ContactInformationWithLoading = WithLoading(ContactInformation);

class DataholderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataholder: null,
      isLoading: true,
      contracts: null
    };

    this.DataholderService = new DataholderService();
  }

  async componentDidMount() {
    var userId = localStorage.getItem("userId");

    this.setState({ isLoading: true });
    const dataholder = await this.DataholderService.get(userId);
    const contracts = await contractsService.getAll(userId);
    this.setState({
      contracts: contracts.value,
      dataholder: dataholder,
      isLoading: false
    });
  }

  render() {
    const { dataholder, isLoading, contracts } = this.state;
    const { classes, appTitle } = this.props;

    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <Typography variant="h3" gutterBottom component="h2">
            {appTitle}
          </Typography>
        </Hidden>
        <DataholderInformationWithLoading
          isLoading={isLoading}
          dataholder={dataholder}
        />
        <ContactInformationWithLoading
          isLoading={isLoading}
          dataholder={dataholder}
        />
        <PaymentMethods contracts={contracts} />
      </div>
    );
  }
}

const Dataholder = connect(mapStateToProps)(DataholderContainer);
export default withStyles(styles)(Dataholder);
