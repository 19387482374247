import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import DataholderData from "./dataholderData/dataholderData";
import ConsumptionData from "./consumptionData/consumptionData";
import InvoiceData from "./invoiceData/invoiceData";
import ContractData from "./contractData/contractData";
import styles from "./dashboard.styles";
import { withTranslation } from 'react-i18next';
import {
  Grid,
  FormControl,
  TextField
} from "@material-ui/core";
import { connect } from "react-redux";
import { selectContract } from "../../redux/actions/contracts.actions";

const mapStateToProps = state => {
  return {
    appTitle: state.core.appTitle,
    contracts: state.contracts.contracts,
    contract: state.contracts.contract,
    dataholder: state.dataholder.dataholder,
    invoices: state.invoices.invoices,
    invoice: state.invoices.invoice,
    cups: state.cups.cups,
    selectedCups: state.cups.selectedCups
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectContract: contractId => {
      dispatch(selectContract(contractId))
    }
  };
}

class ResumeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableContracts: null,
      availableInvoices: null,
      selectedContract: null,
      lastInvoice: null,
      consumptions: null,
      telemedido: null,
      availableCups: null,
      currentCups: null
    };

    this.handleContractChange = this.handleContractChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { invoices, contract, contracts, selectedCups, cups } = nextProps;
    let availableInvoices = null;
    let availableContracts = null;
    let selectedContract = null;
    let consumptions = prevState.consumptions;
    let currentCups = null;
    let availableCups = null;

    invoices !== null ? (availableInvoices = invoices) : (consumptions = null);
    contract !== null ? (selectedContract = contract) : (consumptions = null);
    selectedCups !== null
      ? (currentCups = selectedCups)
      : (consumptions = null);

    cups !== null
      ? (availableCups = cups)
      : (availableCups = prevState.availableCups);

    if (contracts !== null && contracts !== prevState.availableContracts) {
      availableContracts = contracts;
    }

    const contractInvoices = selectedContract && availableInvoices.filter(
      invoice => invoice.contrato.id === selectedContract.id
    );
    const lastInvoice = contractInvoices && contractInvoices[0];

    const cupsTelemedido = currentCups && availableCups && availableCups.filter(
      c => c.codCUPS === currentCups.codCUPS
    )[0];

    return {
      ...prevState,
      availableInvoices,
      availableContracts,
      selectedContract,
      consumptions: consumptions && consumptions.value,
      currentCups,
      availableCups,
      lastInvoice,
      telemedido: cupsTelemedido && cupsTelemedido.telemedido
    };
  }

  handleContractChange(e) {
    this.props.selectContract(e.target.value);
  }

  render() {
    const { classes, contracts, contract, dataholder, t } = this.props;
    const {
      lastInvoice,
      availableInvoices,
      telemedido
    } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          {contracts &&
            contracts.length > 1 &&
            (
              <FormControl className={classes.formControl}>
                <TextField
                  select
                  variant="outlined"
                  margin="dense"
                  id="contract"
                  inputProps={{
                    name: "contract",
                    id: "contract"
                  }}
                  inputlabelprops={{
                    shrink: "true"
                  }}
                  label={t('consumptions.contractInputLabel')}
                  value={contract && contract.id}
                  onChange={this.handleContractChange}
                  SelectProps={{
                    native: true
                  }}
                >
                  {contracts.map((dt, i) => {
                    return (
                      <option key={i} value={dt.id}>
                        {dt.codContrato} - {dt.descripcion}
                      </option>
                    );
                  })}
                </TextField>
              </FormControl>
            )}
        </div>

        <Grid className={classes.grid} container spacing={40}>
          <Grid item xs={12} lg={6}>
            <InvoiceData invoice={lastInvoice} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ContractData contract={contract} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ConsumptionData
              telemedido={telemedido}
              invoice={lastInvoice}
              invoices={availableInvoices}
              paddingTop={0}
              paddingRight={0}
              paddingBottom={0}
              paddingLeft={0}
              showLeyend={false}
              showTitle={false}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <DataholderData dataholder={dataholder} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const Resume = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumeContainer);

export default withTranslation()(withStyles(styles)(Resume));
