import { all, fork } from "redux-saga/effects";
import { watchContracts } from "./contracts.saga";
import { watchDataholder } from "./dataholder.saga";
import { watchInvoices } from "./invoices.saga";
import { watchUser } from "./user.saga";
import { watchCups } from "./cups.saga";
import { watchCore } from "./core.saga";

export default function* sagaWatchers() {
  yield all([
    fork(watchContracts),
    fork(watchDataholder),
    fork(watchInvoices),
    fork(watchUser),
    fork(watchCups),
    fork(watchCore)
  ]);
}
