import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import { Link } from "react-router-dom";
import { CardActions, Divider } from "@material-ui/core";
import { Button, Icon } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import styles from "./contractData.styles";
import { Loading } from "../../../components";

const DataholderData = (props) => {

  const { classes, contract } = props;
  const product = contract && contract.productos[0];
  const cups = product && product.cups[0];
  const address = cups && cups.direccionInstalacion;
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Icon color="secondary" style={{ fontSize: 50 }}>
            assignment
          </Icon>
        }
        title={t('dashboard.contractHeader')}
        titleTypographyProps={{ variant: "h5" }}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.cardData}>
          {contract ? (
            <Fragment>
              {product ? (
                <>
                  <Typography variant="h5" gutterBottom>
                    {product && product.descripcion}
                  </Typography>
                  <Typography variant="body1">
                    {cups && cups.codCUPS}
                  </Typography>
                  <Typography variant="body1">
                    {address && address.direccion}
                  </Typography>
                  <Typography variant="body1">
                    {address && `(${address.direccionCP}) ${address.direccionMunicipio}`}
                  </Typography>
                </>
              ) : (
                  <Typography variant="h5" gutterBottom>
                    {t('dashboard.contractProductNotFound')}
                  </Typography>
                )}
            </Fragment>
          ) : (
              <Loading />
            )}
        </div>
      </CardContent>
      <Divider />
      <CardActions disableActionSpacing>
        {/* <Button color="primary" component={Link} to="/contracts">
            {t('dashboard.contractModifyActionLabel')}
          </Button> */}
        <Button color="primary" component={Link} to="/contracts">
          {t('dashboard.contractShowActionLabel')}
        </Button>
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(DataholderData);
