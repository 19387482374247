import KEYS_BY_HOST from './policyKeys.json';

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_APP_ID,
        authority: `https://areaprivadab2c.b2clogin.com/${process.env.REACT_APP_TENANT_NAME}/${
            KEYS_BY_HOST[window.location.hostname].signInPolicy
        }/`,
        knownAuthorities: ["areaprivadab2c.b2clogin.com"],
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin
    }
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: [process.env.REACT_APP_SCOPES]
};
