import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import ContractSearch from "./contractSearch/contractSearch";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { selectContract } from "../../redux/actions/contracts.actions";
import { Loading } from "../../components";

function mapDispatchToProps(dispatch) {
  return {
    selectContract: contractId => dispatch(selectContract(contractId))
  };
}

const mapStateToProps = state => {
  return {
    appTitle: state.core.appTitle,
    contracts: state.contracts.contracts,
    contract: state.contracts.contract,
    dataholder: state.dataholder.dataholder
  };
};

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit
    }
  },
  loadingContainer: {
    marginTop: "60px"
  }
});

class ContractsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleNavigationToInvoices = this.handleNavigationToInvoices.bind(
      this
    );
  }

  handleNavigationToInvoices(selectedContract) {
    this.props.selectContract(selectedContract);
    this.props.history.push("/invoices");
  }

  render() {
    const { classes, appTitle, contracts, contract, dataholder } = this.props;

    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <Typography variant="h5" gutterBottom component="h2">
            {appTitle}
          </Typography>
        </Hidden>
        {contracts && contract && dataholder ? (
          <ContractSearch
            triggerHandleNavigationToInvoices={this.handleNavigationToInvoices}
            contracts={contracts}
            selectedContract={contract}
            dataholder={dataholder}
          />
        ) : (
            <div className={classes.loadingContainer}>
              <Loading />
            </div>
          )}
      </div>
    );
  }
}

const Contracts = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractsContainer);

export default withStyles(styles)(Contracts);
