import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import store from './redux/store/index';
import './i18n';
import { getForgotPasswordURL } from './helpers';

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
// MSAL imports

const MSAL_ERROR_DESCRIPTION_KEY = 'msal.error.description';


const runApp = () => {
  // WORKAROUND: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/1301
  window.opener = null;
  //

  const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
  const rootElement = document.getElementById('root');
    const msalInstance = new PublicClientApplication(msalConfig);
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter basename={baseUrl}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
      </BrowserRouter>
    </Provider>,
    rootElement
  );
  registerServiceWorker();
};

const checkODataErrorCodes = () => {
  const msalErrorDescription =
    sessionStorage.getItem(MSAL_ERROR_DESCRIPTION_KEY) || window.location.hash;
    if (msalErrorDescription && msalErrorDescription.indexOf('AADB2C90118') >= 0) {      
    // the user requested a forgot password
    sessionStorage.removeItem(MSAL_ERROR_DESCRIPTION_KEY);
    window.location.href = getForgotPasswordURL();
  } else if (msalErrorDescription && msalErrorDescription.indexOf('AADB2C90091') >= 0) {
    // the user canceled the singup process
    sessionStorage.removeItem(MSAL_ERROR_DESCRIPTION_KEY);
    window.location.href = '/';
  } else {
    // if no error codes were found, runs the app.
    try {
      runApp();
    } catch (error) {
      // unhandled oAuth error, redirect to home
      // WORKAROUND: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/701
      window.location.href = '/';
    }
  }
};

window.addEventListener('load', function () {
  const { matchesUA } = require('browserslist-useragent');
  const isASupportedBrowser = matchesUA(navigator.userAgent, {
    allowHigherVersions: true
  });
  if (!isASupportedBrowser) {
    console.error('Unsupported Browser:', navigator.userAgent);
    document.write(
      '<p style="opacity: .8; margin-top:10rem; font-family: monospace; font-size: 16px; text-align: center; line-height: 22px;"><strong>NAVEGADOR NO SOPORTADO</strong> <br /><br />Estás utilizando un navegador no soportado para esta aplicación.<br />Por favor, actualiza la versión de tu navegador.</p>'
    );
  } else {
    // if it's a supported browser, check OData errors.
    checkODataErrorCodes();
  }
});
