import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Typography,
  TextField,
  Divider,
  Paper
} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import { Loading } from "../../../components";

const styles = theme => ({
  form: {
    padding: theme.spacing.unit * 3,
    minHeight: "220px"
  },
  subForm: {
    marginBottom: theme.spacing.unit * 3
  },
  dataholderRow: {
    margin: "0px"
  },
  formActions: {
    padding: theme.spacing.unit * 2
  },
  loadingContainer: {
    marginTop: "50px"
  }
});

class ProfileDataComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileUser: null,
      profileDataholder: null,
      profilePhone: null,
      profilePhone2: null,
      profileEmail: null,
      profileLanguage: null
    };
  }

  handleChange = name => event => {
    this.setState({ ...this.state, [name]: event.target.value });
  };

  render() {
    const { classes, user, dataholder, t } = this.props;
    return (

      user && dataholder ? (
        <Paper>
          <div className={classes.form}>
            <Grid container className={classes.subForm} spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h6" color="secondary">
                  {t('profile.profileDataHeader')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4} xl={2}>
                <TextField
                  variant="outlined"
                  id="profileDataholder"
                  label={t('profile.profileDataholderInputLabel')}
                  style={{ margin: 8 }}
                  placeholder={t('profile.profileDataholderInputLabel')}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    readOnly: true
                  }}
                  value={
                    dataholder != null && dataholder.nombre
                      ? dataholder.nombre
                      : dataholder.razonSocial
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  variant="outlined"
                  id="profileUser"
                  label={t('profile.profileUserInputLabel')}
                  style={{ margin: 8 }}
                  placeholder={t('profile.profileUserInputLabel')}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    readOnly: true
                  }}
                  value={user.email}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.subForm} spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h6" color="secondary">
                  {t('profile.contactDataHeader')}
                </Typography>
              </Grid>
              {dataholder.contactos.map((contacto, index) => {
                return (
                  <Grid container spacing={24} className={classes.dataholderRow} key={index}>
                    <Grid item xs={12} sm={6} md={5} lg={4} xl={2}>
                      <TextField
                        variant="outlined"
                        id="profileContactPerson"
                        label={t('profile.contactPersonInputLabel')}
                        style={{ margin: 8 }}
                        placeholder={t('profile.contactPersonInputLabel')}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                          readOnly: true
                        }}
                        value={
                          contacto.personaContacto
                            ? contacto.personaContacto
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                      <TextField
                        variant="outlined"
                        id="profileEmail"
                        label={t('profile.emailInputLabel')}
                        style={{ margin: 8 }}
                        placeholder={t('profile.emailInputLabel')}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                          readOnly: true
                        }}
                        value={contacto.email ? contacto.email : ""}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} md={1}>
                      <TextField
                        variant="outlined"
                        id="profilePhone"
                        label={t('profile.phoneInputLabel')}
                        style={{ margin: 8 }}
                        placeholder={t('profile.phoneInputLabel')}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                          readOnly: true
                        }}
                        value={contacto.telefono ? contacto.telefono : ""}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container className={classes.subForm} spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h6" color="secondary">
                  {t('profile.preferencesDataHeader')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={1}>
                <TextField
                  variant="outlined"
                  id="profileLanguage"
                  label={t('profile.languageInputLabel')}
                  style={{ margin: 8 }}
                  placeholder={t('profile.languageInputLabel')}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                    readOnly: true
                  }}
                  value={dataholder.idioma}
                />
              </Grid>
            </Grid>
          </div>
          <Divider />
        </Paper >
      ) : (
          <div className={classes.loadingContainer}>
            <Loading />
          </div>
        )
    )
  }
}

export default withTranslation()(withStyles(styles)(ProfileDataComponent));
