import {
  FETCH_CONTRACTS,
  FETCH_CONTRACTS_SUCCESS,
  FETCH_CUPS,
  SELECT_CONTRACT,
  SELECT_CONTRACT_SUCCESS
} from "../constants/action-types";
import { takeLatest, put, select, delay } from "@redux-saga/core/effects";
import * as contractService from "../../services/contracts.service";

export const selectedContract = (state) => state.contracts.contract;

function* fetchContracts(action) {
  try {
    const contracts = yield contractService.getAll(action.payload);

    yield put({ type: FETCH_CONTRACTS_SUCCESS, payload: contracts.value });

    while (true) {
      const contract = yield select(selectedContract);

      if (contract.id !== null) {
        yield put({ type: FETCH_CUPS, payload: contract.id })
        break;
      }
      else {
        yield delay(1000);
      }
    }

  } catch (e) {
    console.log(e);
  }
}


function* selectContract(action) {
  yield put({ type: SELECT_CONTRACT_SUCCESS, payload: action.payload });
  yield put({ type: FETCH_CUPS, payload: action.payload })
}

export function* watchContracts() {
  yield takeLatest(FETCH_CONTRACTS, fetchContracts);
  yield takeLatest(SELECT_CONTRACT, selectContract);
}
