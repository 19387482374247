import { CHANGE_APP_TITLE, SET_LOADING, FETCH_INIT_DATA, SET_ERROR } from "../constants/action-types";

export function changeAppTitle(payload) {
  return { type: CHANGE_APP_TITLE, payload };
}

export function fetchInitData(payload) {
  return { type: FETCH_INIT_DATA, payload };
}

export function setLoading(payload) {
  return { type: SET_LOADING, payload };
}

export function setError(payload) {
  return { type: SET_ERROR, payload };
}