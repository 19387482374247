import * as ErrorHelper from '../helpers';
import i18n from '../i18n';
import { getApimUrl } from '../helpers/configHelper';

export function getAll(dataholderId) {
    return fetch(
        getApimUrl("PrefixEnviromentContratos") +
        '/odata/Contratos?api-version=1.0&$expand=sujeto,productos($expand=cups($expand=direccionInstalacion))&$filter=sujeto/id eq ' +
        dataholderId +
        '&$count=false'
    )
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw Error(response.statusText);
            }
        })
        .catch(error => {
            ErrorHelper.setError(i18n.t('messages.noContractsFoundForCustomer', { dataholderId }));
            console.log('ContractsService.getAll()', error);
        });
}