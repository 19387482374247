import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Button, CardActions, Divider, Icon } from "@material-ui/core";
import styles from "./invoiceData.styles";
import * as documentsService from "../../../services/documents.service";
import { Loading } from "../../../components";

const InvoiceData = (props) => {

  const onHandleRequestFileDownload = () =>
    documentsService.getInvoicePDF(props.invoice.codFactura, "pdf");

  const { classes, invoice } = props;
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Icon color="secondary" style={{ fontSize: 50 }}>
            payment
          </Icon>
        }
        title={t('dashboard.invoicesHeader')}
        titleTypographyProps={{ variant: "h5" }}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.cardData}>
          <Typography variant="h4" gutterBottom>
            {invoice ? (
              new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR"
              }).format(invoice.resumenFactura.totalFactura)
            ) : (
                <Loading />
              )}
          </Typography>
          <Typography variant="body1">
            {invoice && invoice.codFactura}
          </Typography>
          <Typography variant="body1">
            {invoice && `${new Date(invoice.fechaInicio).toLocaleDateString()} - ${new Date(invoice.fechaFin).toLocaleDateString()}`}
          </Typography>
        </div>
      </CardContent>
      <Divider />
      <CardActions disableActionSpacing>
        {invoice && (
          <Button
            color="primary"
            onClick={onHandleRequestFileDownload}
          >
            {t('dashboard.invoicesDownloadActionLabel')}
          </Button>
        )}
        <Button color="primary" component={Link} to="/invoices">
          {t('dashboard.invoicesShowActionLabel')}
        </Button>
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(InvoiceData);
