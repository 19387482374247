import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core/";
import Hidden from "@material-ui/core/Hidden";
import ProfileData from "./profileData/profileData";
import { connect } from "react-redux";
import { changeAppTitle } from "../../redux/actions/core.actions";

function mapDispatchToProps(dispatch) {
  return {
    changeAppTitle: title => dispatch(changeAppTitle(title))
  };
}

const mapStateToProps = state => {
  return {
    appTitle: state.core.appTitle,
    user: state.user.user,
    dataholder: state.dataholder.dataholder
  };
};

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit
    }
  }
});

class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes, appTitle, user, dataholder } = this.props;
    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <Typography
            className="custom-blue"
            variant="h5"
            gutterBottom
            component="h2"
          >
            {appTitle}
          </Typography>
        </Hidden>

        <ProfileData user={user} dataholder={dataholder} />
      </div>
    );
  }
}

const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
export default withStyles(styles)(Profile);
