import React, { Component } from "react";
import { withStyles, Divider, Hidden } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import styles from "./header.styles";
import AAD_B2CService from "../../../services/AAD_B2C.service";
import { withTranslation } from 'react-i18next';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: null,
      anchorEl: null,
      mobileOpen: false
    };

    this.AAD_B2CService = new AAD_B2CService();
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.logout = this.logout.bind(this);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  isAuthenticated() {
    this.setState({
      authenticated: this.AAD_B2CService.loggedIn()
    });
  }

  logout() {
      //this.props.onLogout()
      this.AAD_B2CService.logout();
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  handleDrawerToggle() {
    this.props.triggerCollapseSidebar(false);
  }

  render() {
    const { classes, appTitle, userName, t } = this.props;
    const { anchorEl } = this.state;
    const userEmail = this.AAD_B2CService.email;

    return (
      <AppBar position="fixed" elevation={2} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap className={classes.appBarTitle}>
            <Hidden mdUp implementation="css">
              {appTitle}
            </Hidden>
          </Typography>
          <IconButton
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <Icon className={classes.icon}>person</Icon>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem>
              {t('header.hello')}, {userName || userEmail}
            </MenuItem>
            <Divider />
            <MenuItem
              className={classes.menuItemRoot}
              onClick={this.handleClose} component={Link} to="/profile">
              <ListItemIcon>
                <VerifiedUser />
              </ListItemIcon>
              <ListItemText inset primary={t('header.showProfile')} />
            </MenuItem>
            <MenuItem
              className={classes.menuItemRoot}
              onClick={this.logout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText inset primary={t('header.closeSession')} />
            </MenuItem>
          </Menu>
        </Toolbar>
        <div />
      </AppBar>
    );
  }
}

export default withTranslation()(withStyles(styles)(Header));
