import { GET_USER_SUCCESS} from "../constants/action-types";

const initialState = {
  user: {
    userId: null,
    email: null
  }
};

function dataholderReducer(state = initialState, action) {
  if (action.type === GET_USER_SUCCESS) {
    return Object.assign({}, state, {
      user: action.payload
    });
  }
  return state;
}
export default dataholderReducer;
