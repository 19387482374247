import {
  FETCH_INVOICES,
  FETCH_INVOICES_SUCCESS
} from "../constants/action-types";
import { takeLatest, put } from "@redux-saga/core/effects";
import * as invoicesService from "../../services/invoices.service";

function* fetchInvoices(action) {
  try {
    const invoices = yield invoicesService.getAll(
      action.payload
    );
    yield put({ type: FETCH_INVOICES_SUCCESS, payload: invoices.value });
  } catch (e) {
    console.log('Invoices SAGA ERROR', e);
  }
}

export function* watchInvoices() {
  yield takeLatest(FETCH_INVOICES, fetchInvoices);
}
