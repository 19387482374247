/* CORE */
export const CHANGE_APP_TITLE = "CHANGE_APP_TITLE";
export const FETCH_INIT_DATA = "FETCH_INIT_DATA";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";

/* USER */
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

/* CONTRACTS */
export const FETCH_CONTRACTS = "FETCH_CONTRACTS";
export const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";
export const SELECT_CONTRACT = "SELECT_CONTRACT";
export const SELECT_CONTRACT_SUCCESS = "SELECT_CONTRACT_SUCCESS";


/* DATAHOLDER */
export const GET_DATAHOLDER = "GET_DATAHOLDER";
export const GET_DATAHOLDER_SUCCESS = "GET_DATAHOLDER_SUCCESS";

/* INVOICES */
export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";


/* CUPS */
export const FETCH_CUPS = "FETCH_CUPS";
export const FETCH_CUPS_SUCCESS = "FETCH_CUPS_SUCCESS";
export const SELECT_CUPS = "SELECT_CONTRACT";