import * as ErrorHelper from "../helpers";
import i18n from "../i18n";
import { getApimUrl } from '../helpers/configHelper';

export function getInvoicePDF(invoiceCode, format) {
  return fetch(
      `${getApimUrl("PrefixEnviromentConceptApi")}/api/files/invoice/${invoiceCode}/${format}`
  )
      .then(response => {
          if (response.ok) {
              return response.blob();
          } else {
              throw Error(response.statusText);
          }
          
      })
    .then(blob => {
      if (window.navigator.msSaveOrOpenBlob) {
        // Solution for IE
        var fileData = [blob];
        const blobObject = new Blob(fileData);
        window.navigator.msSaveOrOpenBlob(blobObject, `${invoiceCode}.${format}`);
        
      } else {
        // Other browsers
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${invoiceCode}.${format}`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      }
      
    })
    .catch(error => {
      ErrorHelper.setError(
        i18n.t("messages.noDocumentFound", { invoiceCode, format })
      );
      console.log("DocumentsService.getInvoicePDF()", error);
    });
}
