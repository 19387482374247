
export async function fetchConfiguration(token) {

    const config = {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
    };

    return await fetch(`api/AppConfiguration/FetchConfigurations`, config).then(
        (response) => {
            if (response !== undefined) {
                return response.json();
            } else {
                return [];
            }
        }
    );
}