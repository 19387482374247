import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from '@material-ui/core/CardHeader';
import { CardActions, Divider, Typography, Icon } from "@material-ui/core";
import styles from "./dataholderData.styles";
import { Loading } from "../../../components";

const DataholderData = (props) => {

  const { classes, dataholder } = props;
  const { t } = useTranslation();

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Icon color="secondary" style={{ fontSize: 50 }}>
            person
            </Icon>
        }
        title={t('dashboard.dataholderHeader')}
        titleTypographyProps={{ variant: "h5" }}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.cardData}>
          {dataholder ? (
            <>
              <Typography variant="body1" gutterBottom>
                {dataholder.nombre
                  ? dataholder.nombre
                  : dataholder.razonSocial}
              </Typography>
              <Typography variant="body1">
                {dataholder.direccion
                  ? `${dataholder.direccion.direccion} (${
                  dataholder.direccion.direccionCP
                  }) ${dataholder.direccion.direccionMunicipio}`
                  : t('dashboard.dataholderAddressNotFound')}
              </Typography>
              <Typography variant="body1">
                {dataholder.contactos &&
                  dataholder.contactos[0] &&
                  dataholder.contactos[0].telefono}
              </Typography>
              <Typography variant="body1">
                {dataholder.contactos &&
                  dataholder.contactos[0] &&
                  dataholder.contactos[0].email}
              </Typography>
            </>
          ) : (
              <Loading />
            )}
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions} disableActionSpacing>
        {/* <Button color="primary" component={Link} to="/dataholder">
          {t('dashboard.dataholderModifyActionLabel')}
        </Button> */}
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(DataholderData);
