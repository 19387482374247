import { FETCH_CUPS, FETCH_CUPS_SUCCESS } from '../constants/action-types';
import { takeLatest, put } from '@redux-saga/core/effects';
import * as cupsService from '../../services/cups.service';

function* fetchCups(action) {
  const contractId = action.payload;
  try {
    const response = yield cupsService.getAll(contractId);
    if (response && response.value && response.value.length > 0) {
      const products = response.value[0].productos;
      const cups = products && products.map(product => product.cups && product.cups[0]);
      yield put({ type: FETCH_CUPS_SUCCESS, payload: cups });
    }
  } catch (error) {
    yield put({ type: FETCH_CUPS_SUCCESS, payload: undefined });
    console.error('fetchCups()', error);
  }
}

export function* watchCups() {
  yield takeLatest(FETCH_CUPS, fetchCups);
}
