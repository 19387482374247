import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {
  withStyles,
  Typography,
  Grid,
  Card,
  CardActions,
  CardContent,
  Divider,
  Icon,
  Button,
  Link,
  Hidden
} from "@material-ui/core";

const mapStateToProps = state => {
  return { appTitle: state.core.appTitle };
};

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit
    }
  },
  card: {
    maxWidth: 350
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  cardIcon: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center"
  },
  cardData: {
    textAlign: "left"
  }
});

class ChangesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes, appTitle, t } = this.props;

    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <Typography variant="h4" gutterBottom component="h2">
            {appTitle}
          </Typography>
        </Hidden>
        <Grid container spacing={24}>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.cardContent}>
                  <div className={classes.cardData}>
                    <Typography variant="h5" gutterBottom>
                      {t('changes.customer.header')}
                    </Typography>
                    <Typography variant="subtitle1">
                      {t('changes.customer.helpText')}
                    </Typography>
                  </div>
                  <div className={classes.cardIcon}>
                    <Icon color="secondary" style={{ fontSize: 50 }}>
                      person
                    </Icon>
                  </div>
                </div>
              </CardContent>
              <Divider />
              <CardActions disableActionSpacing>
                <Button color="primary" component={Link} to="/">
                  {t('changes.requestButton')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.cardContent}>
                  <div className={classes.cardData}>
                    <Typography variant="h5" gutterBottom>
                      {t('changes.power.header')}
                    </Typography>
                    <Typography variant="subtitle1">
                      {t('changes.power.helpText')}
                    </Typography>
                  </div>
                  <div className={classes.cardIcon}>
                    <Icon color="secondary" style={{ fontSize: 50 }}>
                      power_input
                    </Icon>
                  </div>
                </div>
              </CardContent>
              <Divider />
              <CardActions disableActionSpacing>
                <Button color="primary" component={Link} to="/">
                  {t('changes.requestButton')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.cardContent}>
                  <div className={classes.cardData}>
                    <Typography variant="h5" gutterBottom>
                      {t('changes.product.header')}
                    </Typography>
                    <Typography variant="subtitle1">
                      {t('changes.product.helpText')}
                    </Typography>
                  </div>
                  <div className={classes.cardIcon}>
                    <Icon color="secondary" style={{ fontSize: 50 }}>
                      track_changes
                    </Icon>
                  </div>
                </div>
              </CardContent>
              <Divider />
              <CardActions disableActionSpacing>
                <Button color="primary" component={Link} to="/">
                  {t('changes.requestButton')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.card}>
              <CardContent>
                <div className={classes.cardContent}>
                  <div className={classes.cardData}>
                    <Typography variant="h5" gutterBottom>
                      {t('changes.paymentData.header')}
                    </Typography>
                    <Typography variant="subtitle1">
                      {t('changes.paymentData.helpText')}
                    </Typography>
                  </div>
                  <div className={classes.cardIcon}>
                    <Icon color="secondary" style={{ fontSize: 50 }}>
                      credit_card
                    </Icon>
                  </div>
                </div>
              </CardContent>
              <Divider />
              <CardActions disableActionSpacing>
                <Button color="primary" component={Link} to="/">
                  {t('changes.requestButton')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const Changes = connect(mapStateToProps)(ChangesContainer);
export default withTranslation()(withStyles(styles)(Changes));
