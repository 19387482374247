import { GET_DATAHOLDER_SUCCESS} from "../constants/action-types";

const initialState = {
  dataholder: null
};

function dataholderReducer(state = initialState, action) {
  if (action.type === GET_DATAHOLDER_SUCCESS) {
    return Object.assign({}, state, {
      dataholder: action.payload
    });
  }
  return state;
}
export default dataholderReducer;
