import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    Grid,
    TextField,
    Tooltip,
    Icon,
    IconButton,
    Hidden
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContractActionsMenu from "../contractActionsMenu/contractActionsMenu";

const styles = theme => ({
    subForm: {
        marginBottom: theme.spacing.unit * 5
    },
    expansionPanel: {
        marginBottom: theme.spacing.unit * 3
    },
    expansionPanelSummary: {
        display: "flex",
        paddingRight: 0
    },
    expansionPanelSummaryContent: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    expansionPanelTitle: {},
    expansionPanelActions: {}
});

class ContractSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            contractDataholder: null,
            contractCUPS: null,
            contractDocumentType: null,
            expanded: 0,
            anchorActions: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleActionsClose = this.handleActionsClose.bind(this);
        this.handleActionsClick = this.handleActionsClick.bind(this);
        this.handleNavigationToInvoices = this.handleNavigationToInvoices.bind(
            this
        );
    }

    handleActionsClose() {
        this.setState({ anchorActions: null });
    }

    handleActionsClick(event) {
        this.setState({ anchorActions: event.currentTarget });
    }

    handleChange = name => event => {
        this.setState({ ...this.state, [name]: event.target.value });
    };

    handleExpansionPanelChange = panel => () => {
        this.setState({
            expanded: panel
        });
    };

    handleNavigationToInvoices(selectedContract) {
        this.props.triggerHandleNavigationToInvoices(selectedContract);
    }

    render() {
        const { classes, contracts, dataholder, t } = this.props;
        const { expanded } = this.state;
        return (
            <div>
                {contracts.map((contract, index) => {
                    return (
                        <ExpansionPanel
                            className={classes.expansionPanel}
                            key={contract.id}
                            expanded={expanded === index}
                        >
                            <ExpansionPanelSummary className={classes.expansionPanelSummary}>
                                <div className={classes.expansionPanelSummaryContent}>
                                    <div className={classes.expansionPanelTitle}>
                                        <Typography variant="h5" color="primary">
                                            {contract.codContrato} - {contract.descripcion}
                                        </Typography>
                                    </div>
                                    <div className={classes.expansionPanelActions}>
                                        {expanded === index && (
                                            <Fragment>
                                                <Hidden only={["xs", "sm"]} implementation="css">
                                                    {/* <Tooltip title="Modificar">
                            <IconButton aria-label="Modificar">
                              <Icon color="secondary">edit</Icon>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Descargar en PDF">
                            <IconButton aria-label="PDF">
                              <PictureAsPdfIcon  color="secondary"/>
                            </IconButton>
                          </Tooltip> */}
                                                    <Tooltip
                                                        title={t("contracts.showInvoicesActionLabel")}
                                                    >
                                                        <IconButton
                                                            onClick={() =>
                                                                this.handleNavigationToInvoices(contract.id)
                                                            }
                                                        >
                                                            <Icon color="secondary">file_copy</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Hidden>

                                                <Hidden only={["md", "lg", "xl"]} implementation="css">
                                                    <ContractActionsMenu
                                                        contract={contract}
                                                        triggerHandleNavigationToInvoices={
                                                            this.handleNavigationToInvoices
                                                        }
                                                    />
                                                </Hidden>
                                            </Fragment>
                                        )}
                                        {expanded !== index && (
                                            <ExpandMoreIcon
                                                onClick={this.handleExpansionPanelChange(index)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid container className={classes.subForm} spacing={24}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" color="secondary">
                                                {t("contracts.contractDataHeader")}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                variant="outlined"
                                                id="contractDataholder"
                                                label={t("contracts.dataHolderInputLabel")}
                                                style={{ margin: 8 }}
                                                placeholder={t("contracts.dataHolderInputLabel")}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    readOnly: true
                                                }}
                                                value={
                                                    dataholder.nombre
                                                        ? dataholder.nombre
                                                        : dataholder.razonSocial
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                id="contractDocumentType"
                                                label={t("contracts.documentTypeInputLabel")}
                                                style={{ margin: 8 }}
                                                placeholder={t("contracts.documentTypeInputLabel")}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    readOnly: true
                                                }}
                                                value={dataholder.tipoDocumento}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                id="contractDocumentNumber"
                                                label={t("contracts.documentNumberInputLabel")}
                                                style={{ margin: 8 }}
                                                placeholder={t("contracts.documentNumberInputLabel")}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    readOnly: true
                                                }}
                                                value={dataholder.documentoFiscal}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                id="contractNumber"
                                                label={t("contracts.contractNumberInputLabel")}
                                                style={{ margin: 8 }}
                                                placeholder={t("contracts.contractNumberInputLabel")}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    readOnly: true
                                                }}
                                                value={contract.codContrato}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.subForm} spacing={24}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" color="secondary">
                                                {t("contracts.productHeader")}
                                            </Typography>
                                        </Grid>
                                        {contract.productos.map((product, index) => {
                                            return product.cups.map((cups, i) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <Grid item xs={12} md={3}>
                                                            <TextField
                                                                variant="outlined"
                                                                id="contractProduct"
                                                                label={t("contracts.productInputLabel")}
                                                                style={{ margin: 8 }}
                                                                placeholder={t("contracts.productInputLabel")}
                                                                fullWidth
                                                                margin="normal"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    readOnly: true
                                                                }}
                                                                value={product.descripcion}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={3}>
                                                            <TextField
                                                                variant="outlined"
                                                                id="contractCUPS"
                                                                label={t("contracts.cupsInputLabel")}
                                                                style={{ margin: 8 }}
                                                                placeholder={t("contracts.cupsInputLabel")}
                                                                fullWidth
                                                                margin="normal"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    readOnly: true
                                                                }}
                                                                value={cups.codCUPS}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                variant="outlined"
                                                                id="contractSupplyAddress"
                                                                label={t("contracts.supplyAddressInputLabel")}
                                                                style={{ margin: 8 }}
                                                                placeholder={t(
                                                                    "contracts.supplyAddressInputLabel"
                                                                )}
                                                                fullWidth
                                                                margin="normal"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                    readOnly: true
                                                                }}
                                                                value={
                                                                    cups &&
                                                                        cups.direccionInstalacion.direccion &&
                                                                        cups.direccionInstalacion.direccionCP &&
                                                                        cups.direccionInstalacion.direccionMunicipio
                                                                        ? `${cups.direccionInstalacion.direccion} (${cups.direccionInstalacion.direccionCP}) ${cups.direccionInstalacion.direccionMunicipio}`
                                                                        : ""
                                                                }
                                                            />
                                                        </Grid>
                                                    </Fragment>
                                                );
                                            });
                                        })}
                                    </Grid>

                                    <Grid container className={classes.subForm} spacing={24}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" color="secondary">
                                                {t("contracts.billingHeader")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                id="contractBillingDataholder"
                                                label={t("contracts.billingDataHolderInputLabel")}
                                                style={{ margin: 8 }}
                                                placeholder={t("contracts.billingDataHolderInputLabel")}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    readOnly: true
                                                }}
                                                value={
                                                    dataholder.nombre
                                                        ? dataholder.nombre
                                                        : dataholder.razonSocial
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                id="contractBillingAddress"
                                                label={t("contracts.billingAddressInputLabel")}
                                                style={{ margin: 8 }}
                                                placeholder={t("contracts.billingAddressInputLabel")}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    readOnly: true
                                                }}
                                                value={
                                                    contract &&
                                                        contract.metodoPago &&
                                                        contract.metodoPago.direccionFacturacion
                                                        ? `${contract.metodoPago.direccionFacturacion.direccion} (${contract.metodoPago.direccionFacturacion.direccionCP}) ${contract.metodoPago.direccionFacturacion.direccionMunicipio}`
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                id="contractBillingAccountNumber"
                                                label={t("contracts.billingAccountNumberInputLabel")}
                                                style={{ margin: 8 }}
                                                placeholder={t(
                                                    "contracts.billingAccountNumberInputLabel"
                                                )}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    readOnly: true
                                                }}
                                                value={
                                                    contract &&
                                                        contract.metodoPago &&
                                                        contract.metodoPago.iban
                                                        ? contract.metodoPago.iban
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                variant="outlined"
                                                id="contractBillingPaymentMethod"
                                                label={t("contracts.billingPaymentMethodInputLabel")}
                                                style={{ margin: 8 }}
                                                placeholder={t(
                                                    "contracts.billingPaymentMethodInputLabel"
                                                )}
                                                fullWidth
                                                margin="normal"
                                                InputLabelProps={{
                                                    shrink: true,
                                                    readOnly: true
                                                }}
                                                value={
                                                    contract &&
                                                        contract.metodoPago &&
                                                        contract.metodoPago.tipoMetodoPago
                                                        ? contract.metodoPago.tipoMetodoPago
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    );
                })}
            </div>
        );
    }
}

export default withTranslation()(withStyles(styles)(ContractSearch));