import React, { Component, Fragment } from "react";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {
  withStyles,
  Hidden,
  Icon,
  Typography,
  List,
  ListItem,
  Divider,
  IconButton,
  Toolbar,
  Tooltip
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { withTranslation } from 'react-i18next';
import styles from "./invoiceList.styles";
import Chip from "@material-ui/core/Chip";
import { createMuiTheme } from "@material-ui/core/styles";
import * as documentsService from "../../../services/documents.service";
import { Link } from "react-router-dom";
import InvoiceActionsMenu from "../invoiceActionsMenu/invoiceActionsMenu";
import { Loading } from "../../../components";

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleRequestFileDownload = this.handleRequestFileDownload.bind(this);
  }

  handleChangePage = page => {
    this.props.triggerChangePage(page);
  };

  handleRequestFileDownload(invoiceCode, format) {
    documentsService.getInvoicePDF(invoiceCode, format);
  }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "#FF0000"
          }
        }
      }
    });

  render() {
    const { classes, invoices, title, invoicesCount, t } = this.props;
    const data = this.parseInvoices(invoices);

    const columns = [
      {
        name: t('invoices.tableColumns.invoiceNumber'),
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: t('invoices.tableColumns.contract'),
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: t('invoices.tableColumns.period'),
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: t('invoices.tableColumns.state'),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <Chip label={value} />;
          }
        }
      },
      {
        name: t('invoices.tableColumns.date'),
        options: {
          filter: false,
          sort: false
        }
      },
      {
        name: t('invoices.tableColumns.amount'),
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "",
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            var currentInvoice = invoices.filter(
              c => c.codFactura === value
            )[0];
            return (
              <Toolbar className={classes.gridInvoiceActions}>
                <Tooltip
                  title={t('invoices.downloadPdfActionLabel')}
                  onClick={() => {
                    this.handleRequestFileDownload(value, "pdf");
                  }}
                >
                  <IconButton aria-label="PDF">
                    <PictureAsPdfIcon color="secondary" />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip title="Descargar en XML" onClick={() => {this.handleRequestFileDownload(value, 'xml')}}>
                  <IconButton aria-label="XML">
                    <Icon>arrow_downward</Icon>
                  </IconButton>
                </Tooltip> */}
                <Tooltip title={t('invoices.showConsumptionsActionLabel')}>
                  <IconButton
                    component={Link}
                    to={`/consumptions/${currentInvoice.contrato.codContrato}/${
                      currentInvoice.cups.codCUPS
                      }/${currentInvoice.fechaInicio}/${currentInvoice.fechaFin}`}
                  >
                    <Icon color="secondary">bar_chart</Icon>
                  </IconButton>
                </Tooltip>
              </Toolbar>
            );
          }
        }
      }
    ];

    const options = {
      filterType: "multiselect",
      serverSide: false,
      print: false,
      download: false,
      selectableRows: "none",
      pagination: false,
      count: invoicesCount,
      //page: 0,
      onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            this.handleChangePage(tableState.page);
            break;
          default:
            break;
        }
      },
      textLabels: {
        body: {
          noMatch: t('invoices.tableTextLabels.body.noMatch'),
          toolTip: t('invoices.tableTextLabels.body.toolTip')
        },
        pagination: {
          next: t('invoices.tableTextLabels.pagination.next'),
          previous: t('invoices.tableTextLabels.pagination.previous'),
          rowsPerPage: t('invoices.tableTextLabels.pagination.rowsPerPage'),
          displayRows: t('invoices.tableTextLabels.pagination.displayRows')
        },
        toolbar: {
          search: t('invoices.tableTextLabels.toolbar.search'),
          downloadCsv: t('invoices.tableTextLabels.toolbar.downloadCsv'),
          print: t('invoices.tableTextLabels.toolbar.print'),
          viewColumns: t('invoices.tableTextLabels.toolbar.viewColumns'),
          filterTable: t('invoices.tableTextLabels.toolbar.filterTable')
        },
        filter: {
          all: t('invoices.tableTextLabels.filter.all'),
          title: t('invoices.tableTextLabels.filter.title'),
          reset: t('invoices.tableTextLabels.filter.reset')
        },
        viewColumns: {
          title: t('invoices.tableTextLabels.viewColumns.title'),
          titleAria: t('invoices.tableTextLabels.viewColumns.titleAria')
        },
        selectedRows: {
          text: t('invoices.tableTextLabels.selectedRows.text'),
          delete: t('invoices.tableTextLabels.selectedRows.delete'),
          deleteAria: t('invoices.tableTextLabels.selectedRows.deleteAria')
        }
      }
    };

    return (
      <Fragment>
        <Hidden only={["xs", "sm"]} implementation="css">
          {invoices ? (
            <MUIDataTable
              title={title}
              data={data}
              columns={columns}
              options={options}
              theme={this.getMuiTheme()}
              responsive="scroll"
            />
          ) : (
              <div className={classes.loadingContainer}>
                <Loading />
              </div>
            )}
        </Hidden>
        <Hidden only={["md", "lg", "xl"]} implementation="css">
          {invoices ? (
            <List>
              {invoices.map((invoice, index) => {
                return (
                  <Fragment key={index}>
                    <ListItem key={index} className={classes.invoice}>
                      <div className={classes.invoiceDataContent}>
                        <div className={classes.invoiceData}>
                          <Typography variant="subtitle1">
                            {invoice.codContrato}
                          </Typography>
                          <Typography variant="subtitle2">
                            {new Intl.NumberFormat("es-ES", {
                              style: "currency",
                              currency: "EUR"
                            }).format(invoice.resumenFactura.totalFactura)}
                          </Typography>
                        </div>
                        <div className={classes.invoiceData}>
                          <Typography variant="caption">
                            {new Date(
                              invoice.fechaInicio
                            ).toLocaleDateString() +
                              " - " +
                              new Date(invoice.fechaFin).toLocaleDateString()}
                          </Typography>
                          <Chip label={invoice.estadoFactura} />
                        </div>
                      </div>
                      <div className={classes.invoiceActions}>
                        <InvoiceActionsMenu
                          invoice={invoice}
                          handleRequestFileDownload={
                            this.handleRequestFileDownload
                          }
                        />
                      </div>
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              })}
            </List>
          ) : (
              <div className={classes.loadingContainer}>
                <Loading />
              </div>
            )}
        </Hidden>
      </Fragment>
    );
  }

  parseInvoices(invoices) {
    if (!invoices) {
      return [];
    }
    return invoices.map(
      ({
        codFactura,
        contrato,
        fechaInicio,
        fechaFin,
        fechaEmision,
        resumenFactura,
        estadoFactura
      }) => [
          codFactura,
          contrato.codContrato,
          new Date(fechaInicio).toLocaleDateString() +
          " - " +
          new Date(fechaFin).toLocaleDateString(),
          estadoFactura,
          new Date(fechaEmision).toLocaleDateString(),
          new Intl.NumberFormat("es-ES", {
            style: "currency",
            currency: "EUR"
          }).format(resumenFactura.totalFactura),
          codFactura
        ]
    );
  }
}

export default withTranslation()(withStyles(styles)(InvoiceList));
