export default theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing.unit * 5,
    marginLeft: theme.spacing.unit * 20,
    marginRight: theme.spacing.unit * 20,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing.unit
    }
  },
  grid: {
    width: "60vw",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      width: "75vw"
    },
    [theme.breakpoints.down("sm")]: {
      width: "95vw"
    }
  },
  gridLayout: {
    display: "flex",
    justifyContent: "stretch"
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  }
});
