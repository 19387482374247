import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./dataholderInformation.styles";
import DataholderLegalTypeInformation from "./dataholderLegalTypeInformation";
import DataholderPhysicalTypeInformation from "./dataholderPhysicalTypeInformation";

class DataholderInformation extends Component {
  state = {};

  render() {
    const { classes, dataholder } = this.props;

    let dataholderInformation;

    if (dataholder.tipoPersona.toUpperCase() === "JURIDICA") {
      dataholderInformation = (
        <DataholderLegalTypeInformation dataholder={dataholder} />
      );
    } else {
      dataholderInformation = (
        <DataholderPhysicalTypeInformation dataholder={dataholder} />
      );
    }

    return (
      <div className={classes.root}>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Datos del titular</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>{dataholderInformation}</ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(DataholderInformation);
