import { getApimUrl } from '../helpers/configHelper';

export function getAll(contractId) {
  return fetch(
      `${getApimUrl("PrefixEnviromentContratos")}/odata/Contratos?api-version=1.0&$expand=productos($expand=cups)&$filter=id eq ${contractId}`
  )
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw Error(response.statusText);
      }
    })
    .catch(error => {
      console.error('CUPSService.get()', error);
    });
}
